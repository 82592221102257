import React, { useState, useCallback, useEffect } from "react";
import formule from "../assets/images/formule-calculatrice.png";

export default function Simulator() {
  const [tableContent] = useState([
    {
      header: ["VARIABLE", "CONDITION", "OUI(1) / NON(0)"],
      content: [
        {
          title: "Niveau hemoglobine",
          rows: ["<135g/L ou 13.5g/dL"],
        },
        {
          title: "poids",
          rows: ["<77kg"],
        },
        {
          title: "Genre",
          rows: ["Femme"],
        },
        {
          title: "Age",
          rows: [">65 ans"],
        },
        {
          title: "Niveau de créatinine sérique",
          rows: [">120micromol/L"],
        },
        {
          title: "Chirurgie Cardiaque",
          rows: ["Non programmée", "antécédents chirurgicaux", "Non isolée"],
        },
      ],
    },
  ]);

  const [riskLevels] = useState([
    {
      value: 0,
      level: "Risque de base",
      color: "#c9daf8",
      expoValue: "0,00 - 0,19",
    },
    {
      value: 1,
      level: "Risque faible",
      color: "#cfe0e3",
      expoValue: "0,20 - 1,39",
    },
    {
      value: 2,
      level: "Risque intermèdiaire",
      color: "#fff2cc",
      expoValue: "0,40 - 0,59",
    },
    {
      value: 3,
      level: "Haut risque",
      color: "#f4cccc",
      expoValue: "0,60 - 0,79",
    },
    {
      value: 4,
      level: "très haut risque",
      color: "#dd7e6b",
      expoValue: "0,80 - 1,00",
    },
    {
      value: 5,
      level: "très haut risque",
      color: "#dd7e6b",
      expoValue: "0,80 - 1,00",
    },
    {
      value: 6,
      level: "très haut risque",
      color: "#dd7e6b",
      expoValue: "0,80 - 1,00",
    },
    {
      value: 7,
      level: "très haut risque",
      color: "#dd7e6b",
      expoValue: "0,80 - 1,00",
    },
    {
      value: 8,
      level: "très haut risque",
      color: "#dd7e6b",
      expoValue: "0,80 - 1,00",
    },
  ]);

  const [totalScore, setTotalScore] = useState(0);

  const variableValueHandler = useCallback(
    (e) => {
      const value = e.target.value;
      setTotalScore(value === "1" ? totalScore + 1 : totalScore - 1);
    },
    [totalScore]
  );

  useEffect(() => {
    console.log("totalScoreaaaaazqxc", totalScore);
  }, [totalScore]);

  return (
    <>
      <div className="contact">
        <section className="s-section">
          <h1>Calculatrice TRUST</h1>
          <table className="s-table">
            <thead className="s-head">
              <tr>
                {tableContent[0].header.map((col) => (
                  <>
                    <th>{col}</th>
                  </>
                ))}
              </tr>
            </thead>
            <tbody className="s-body">
              {tableContent[0].content.map((content) => (
                <tr>
                  <td>
                    <div className="s-body-title">{content.title}</div>
                  </td>
                  <td style={{ padding: 0 }}>
                    <div>
                      {content.rows.map((row) => (
                        <div className="s-table-row">{row}</div>
                      ))}
                    </div>
                  </td>
                  <td style={{ padding: 0 }}>
                    <div>
                      {content.rows.map(() => (
                        <div className="s-table-row">
                          <select
                            className="s-select"
                            onChange={variableValueHandler}
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                          </select>
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="in-section-container">
            <div className="in-section">
              <div className="in-score in-score-title">
                SCORE TOTAL<sup>*</sup>
              </div>
              <div className="in-score in-score-title">{totalScore}</div>
            </div>
            <div className="in-section">
              <div className="in-score in-score-title">
                Interpretation du risque
              </div>
              <div className="in-score">
                <div
                  className="in-text-st"
                  style={{
                    background: riskLevels.filter(
                      (el) => el?.value === totalScore
                    )[0].color,
                  }}
                >
                  {riskLevels.filter((el) => el?.value === totalScore)[0].level}
                </div>
              </div>
            </div>
            <div className="in-section">
              <div className="in-score in-score-title">
                Probabilité d'exposition
              </div>
              <div className="in-score">
                <div className="">
                  {
                    riskLevels.filter((el) => el?.value === totalScore)[0]
                      .expoValue
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="formule">
            <div>*</div>
            <img src={formule} />
          </div>
          <div className="simulator-references">Abdullah A et al. Development and validation of Transfusion Risk Understanding Scoring Tool (TRUST) to stratify cardiac surgery patients according to their blood transfusion needs. July 2006, Volume 46.</div>
          <div className="lowPageMargins"></div>
        </section>
      </div>
    </>
  );
}
