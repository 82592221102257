import React, { useEffect, useState } from "react";
import Scroll from "./buttons/Scroll";
// import circuit from "../assets/images/illu 01.jpg";
import { Slider } from "./slider";
import { Slide1_1 } from "./slides/1/Slide1_1";
import { Slide1_2 } from "./slides/1/Slide1_2";
import { Slide1_3 } from "./slides/1/Slide1_3";
import { Slide1_4 } from "./slides/1/Slide1_4";
import { ReactComponent as CirctuiBar } from "../assets/images/circuitbar.svg";
import { ReactComponent as CirctuiBarMobile } from "../assets/images/circuitbarmobile.svg";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { useSearchParams } from "react-router-dom";

const slides = [
  {
    slide: Slide1_1,
    title: "Les produits sanguins labiles",
    subTitle: "La sélection des donneurs",
    time: "16 min",
    nextButton: "Code et tarification",
    size: "minor",
  },
  {
    slide: Slide1_2,
    title: "",
    subTitle: "Code et tarification",
    time: "4 min",
    nextButton: "Données PMSI",
    size: "minor",
  },
  {
    slide: Slide1_3,
    title: "",
    subTitle: "Les données PMSI<sup>1</sup>",
    time: "8 min",
    nextButton: "Les produits dérivés du sang",
    size: "minor",
  },
  {
    slide: Slide1_4,
    title: "Les produits dérivés du sang",
    subTitle: "",
    time: "6 min",
    nextButton: "",
    size: "major",
  },
];

export default function Circuit() {
  const [isClicked, setisClicked] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1251 });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1250 });

  // TODO: DELETE ?
  // const isBigScreen = useMediaQuery({ minWidth: 1824 });
  // const isPortrait = useMediaQuery({ orientation: "portrait" });
  // const isRetina = useMediaQuery({ minResolution: "2dppx" });

  const isMobile = useMediaQuery({ maxWidth: 767 });
  // console.log(isMobile);
  useEffect(() => {
    // ^ => XOR GATE
    if (isDesktopOrLaptop ^ isTabletOrMobile) {
      const fn = (event) => {
        const target = event.target.closest("text");
        const page = target.dataset["page"];
        goTo(page);
      };
      const goTo = (page) => {
        setSearchParams({ page }, { replace: true });
        setisClicked(true);
      };

      const page = searchParams.get("page");
      if (Number.isInteger(parseInt(page))) {
        goTo(page);
      }
      const links = document.querySelectorAll("text[data-page]");
      links.forEach((el) => el.addEventListener("click", fn));
      return () => {
        links.forEach((el) => el.removeEventListener("click", fn));
      };
    }
  }, [isDesktopOrLaptop, isTabletOrMobile]);

  useEffect(() => {
    let IDLOGO = document.getElementById("logoPrincipale");

    if (isClicked) {
      IDLOGO.style.fill = "unset";
    } else {
      setSearchParams({}, { replace: true });
    }
    return () => {
      IDLOGO.style.fill = "";
    };
  }, [isClicked]);

  return (
    <>
      {isDesktopOrLaptop && (
        <div className="circuit">
          <div
            className={classNames(
              "circuit_block",
              isClicked ? "reversedAnimation" : "forrwardAnimation"
            )}
            // style={isClicked ? { top: "100%" } : { top: "0%" }}
          >
            <div className="headerBlock"></div>
            <div>
              {" "}
              <CirctuiBar className="Image" />
            </div>
          </div>
          <div
            className="circuit__scroll btn"
            onClick={() => setisClicked(!isClicked)}
          >
            <div
              style={{ height: "100%", width: "100%", position: "relative" }}
            >
              <Scroll
                style={{
                  rotate: isClicked ? "180deg" : "0deg",
                  display: "block",
                }}
              />
            </div>
          </div>
          <div
            className="Circuit_sliderBlock"
            style={isClicked ? { top: "0%" } : { top: "100%" }}
          >
            <Slider key={isClicked} slides={slides} displayed={isClicked} />
            {/* </> */}
            {/* )} */}
          </div>
        </div>
      )}
      {isTabletOrMobile && (
        <>
          <>
            <div
              className="Circuit_mob"
              style={{
                height: "100vh",
                width: "100%",
                display: isClicked && "none",
              }}
            >
              <div className="circuit " style={{ minHeight: "50vh" }}></div>
              <div
                className={classNames("circuit_block reversedAnimation-mobile")}
                // style={isClicked ? { top: "100%" } : { top: "0%" }}
                style={{
                  width: "100%",
                  height: "50vh",
                  bottom: "0",
                }}
              >
                <div
                  style={{
                    marginLeft: "1.5rem",
                    marginTop: "1rem",
                    width: `90%`,
                  }}
                >
                  {" "}
                  <CirctuiBarMobile className="Image" />
                </div>
              </div>
            </div>

            <div
              style={{
                height: "15%",
                width: isMobile ? "13%" : "7%",
                position: "fixed",
                bottom: isClicked ? "80%" : "1%",
                right: "6%",
                zIndex: 2,
                transition: "all ease 0.75s",
              }}
              className="scroll_icon"
            >
              <Scroll
                isTabletOrMobile={isTabletOrMobile}
                style={{
                  rotate: isClicked ? "180deg" : "0deg",
                  display: "block",
                  transition: "rotate ease 0.5s",
                }}
                onClick={() => setisClicked(!isClicked)}
              />
            </div>
            <div
              className="Circuit_sliderBlock"
              style={
                isClicked
                  ? { top: "0%", width: "100%" }
                  : { top: "100%", width: "100%", display: "none" }
              }
            >
              <Slider displayed={isClicked} key={isClicked} slides={slides} />
              {/* </> */}
              {/* )} */}
            </div>
          </>
        </>
      )}
    </>
  );
}
