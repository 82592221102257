import "./components/slider/carousel.css";

import "./App.css";

import Main from "./components/header/Main";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Acceuil from "./components/Acceuil";
import Circuit from "./components/Circuit";
import Patient from "./components/Patient";
import Contact from "./components/Contact";
import NoPage from "./components/NoPage";
import Simulator from "./components/Simulator";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Ml from "./components/Ml";
// import { useMediaQuery } from "react-responsive";

function App() {
  const location = useLocation();
  const CurrentPath = location.pathname;
  // const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  useEffect(() => {
    switch (CurrentPath) {
      case "/circuit":
        document.documentElement.dataset["theme"] = "blue";
        break;
      case "/patient":
        document.documentElement.dataset["theme"] = "orange";
        break;
      default:
        delete document.documentElement.dataset["theme"];
        break;
    }
  }, [CurrentPath]);

  return (
    <div className="App">
      <Main />
      <Routes>
        <Route path="/" element={<Acceuil />}>
          <Route path="*" element={<NoPage />} />
        </Route>
        <Route path="/circuit" element={<Circuit />} />
        <Route path="/patient" element={<Patient />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/mentionlegal" element={<Ml />} />
        <Route path="/simulator" element={<Simulator />} />
      </Routes>
    </div>
  );
}

export default App;
