// article is the main container
// must put section inside the article to add the margin bottom between sections
// div in the section will add margin to the left
// div.half_to_right will put the div to right in half size 50% to the parent
// ul and li still need a droplet for it but it work fines for now
// p will be just for breaking the line or paragraph
// b will be for bold
import { ReactComponent as PDF } from "../../../assets/images/pdf.svg";
import prd_delie_histo_pdf from "../../../assets/pdf/BAT_Infographie_Histoire_du_PBM MKT-EJC.pdf";
export function Slide2_1(props) {
  return (
    <article>
      <section>
        <p>
          <b>L'histoire du Patient Blood Management</b>
        </p>
        <p>
          Le Patient Blood Management (PBM), ou « gestion personnalisée du
          capital sanguin », correspond à une stratégie multidisciplinaire
          alliant des concepts médicaux et chirurgicaux conçus pour gérer
          l&#39;anémie, optimiser l&#39;hémostase et minimiser les pertes de
          sang afin d&#39;améliorer la prise en charge des patients<sup>1</sup>.
        </p>
      </section>

      <section>
        <p>
          <b>
            La gestion du capital sanguin : une problématique qui s’est posée
            très tôt
          </b>
        </p>
        <p>
          L’histoire de la première transfusion sanguine remonte à 1670 lorsque
          Jean Baptiste Denis, médecin parisien, réalise la première transfusion
          sanguine en transfusant du sang de veau en voulant soigner un patient
          souffrant de maladie mentale. Le Parlement interdira toute transfusion
          à la suite du décès du patient. Ce n’est qu’un siècle et demi plus
          tard que de nouvelles expériences seront réalisées<sup>2</sup>.
        </p>
      </section>

      <section>
        <p>
          Au début du XXe siècle, le docteur Karl Landsteiner découvre les
          groupes sanguins ABO. Les avantages significatifs de la transfusion
          sanguine contre l&#39;anémie s&#39;imposent peu à peu, elle devient
          alors une pratique médicale courante<sup>2,3</sup>. En parallèle de
          ces avancées sur la biocompatibilité, plusieurs techniques pour
          obtenir, traiter et stocker le sang sont développées<sup>3</sup>.
        </p>
      </section>

      <section>
        <p>
          La découverte de propriétés anticoagulantes de l’acide citrique
          remonte à 1892 par Pekelharing. En 1893, Wright émet l’hypothèse que
          l’ajout d’acide citrique au sang est probablement une combinaison plus
          adaptée aux transfusions que le sang oxalaté. Cependant, la première
          transfusion sanguine avec du sang citraté ne sera effectuée qu’en 1914
          par Hustin<sup>4</sup> . En 1916, Rous et Turner décrivent la conservation de
          sang humain jusqu’à 4 semaines par l’utilisation d’un mélange d’acide
          citrique et de dextrose<sup>4,5</sup>.
        </p>
      </section>

      <section>
        <p>
          <b>La transfusion sanguine å l'ére du SIDA</b>
        </p>
        <p>
          Au début des années 1980, les centres américains pour le contrôle et
          la prévention des maladies (CDC) alertent l&#39;industrie du sang
          d&#39;une menace émergente. Le Dr Bruce Evatt, un spécialiste de
          l&#39;hémophilie, identifie une augmentation des cas de pneumonie à
          Pneumocystis carinii (PCP) chez les hémophiles, attribuant cela à
          l&#39;utilisation du facteur VIII contaminé, une protéine essentielle
          de la coagulation sanguine distribuée par l&#39;industrie du sang
          <sup>7</sup>. Un cancer rare et mortel est également signalé, le
          sarcome de Kaposi, parmi un groupe d&#39;homosexuels aux États-Unis.
          En 1982, le terme SIDA (syndrome d&#39;immunodéficience acquise) est
          utilisé pour la première fois. En 1984, des chercheurs identifient la
          cause de cette maladie comme étant le virus de l&#39;immunodéficience
          humaine (VIH), transmis par contact sexuel, partage de seringues et
          produits sanguins
          <sup>7,8,9</sup>.
        </p>
      </section>
      <section>
        <p>
          L&#39;industrie du sang doit alors agir rapidement. En 1985, des tests
          de dépistage du virus dans le sang sont introduits. Malheureusement,
          ce retard a des conséquences dramatiques : beaucoup de personnes ont
          déjà contracté la maladie par l&#39;utilisation de produits sanguins.
          Dans les années 1990, des conditions plus restrictives pour les
          donneurs de sang et un meilleur traitement des produits sanguins sont
          introduits<sup>9</sup>.
        </p>
      </section>
      <section>
        <p>
          Aujourd&#39;hui, même si l&#39;incidence de l&#39;infection à VIH
          transmise par le sang est faible dans les pays développés, le VIH
          n&#39;en demeure pas moins encore une préoccupation mondiale majeure
          <sup>9</sup>. Avec près de 38 millions de séropositifs dans le monde
          en 2020, le dépistage du VIH dans les poches de sang reste un enjeu
          pour la sécurité des donneurs et des receveurs<sup>10</sup>.
        </p>
      </section>
      <section>
        <p>
          Ce scandale du sang contaminé a mis en évidence l’urgence d&#39;une
          alternative pouvant réduire ou éliminer l&#39;utilisation de produits
          sanguins allogéniques. Au cours des deux dernières décennies, les
          recherches sur les techniques de chirurgie sans transfusion se sont
          accélérées, ainsi que le nombre d&#39;institutions disposées à offrir
          cette forme de traitement alternatif<sup>9</sup>.
        </p>
      </section>

      <section>
        <p>
          <b>
            L'encadrement de la transfusion sanguine : une nécessité pour Ia
            sécurité des patients
          </b>
        </p>
        <p>
          Depuis quelques années déjà, la notion de “Gestion du capital sanguin”
          ou “Patient blood management” (en anglais) a pour but d’améliorer la
          prise en charge des patients recourant potentiellement à une
          transfusion sanguine. Cette démarche est fondée sur le principe que la
          transfusion sauve des vies mais elle peut être délétère pour les
          patients qui y sont exposés. Son objectif est de mieux contrôler les
          facteurs de risque de transfusion tout en réduisant l&#39;exposition
          inutile aux produits sanguins. Des organismes sanitaires français et
          internationaux à l’instar de l’Organisation Mondiale de la Santé
          recommandent à cet effet les programmes de gestion du capital sanguin
          (PBM)<sup>11</sup>.
        </p>
      </section>
      <br />
      <figure className="half_to_right">
        <div style={{ width: "100px" }}>
          <a href={prd_delie_histo_pdf} target="_blank" rel="">
            <PDF />
          </a>
        </div>
        <figcaption className="caption">
          {" "}
          Infographie - Histoire du PBM
        </figcaption>
      </figure>

      <br></br>
      <section>
        <div style={{ marginLeft: "0rem" }}>
          <p>
            <sup>1</sup>European Commission, Consumers, Health, Agriculture and
            Food Executive Agency, Gombotz, H., Kastner, P., Nørgaard, A., et
            al., Supporting Patient Blood Management (PBM) in the EU : a
            practical implementation guide for hospitals, Publications Office,
            2017,{" "}
            <a
              href=" https://data.europa.eu/doi/10.2818/533179"
              target="_blank"
            >
              https://data.europa.eu/doi/10.2818/533179
            </a>{" "}
          </p>
          <p>
            <sup>2</sup>La transfusion sanguine de 1670 à nos jours. Sur le
            site de l’Etablissement Français du Sang [en ligne],{" "}
            <a
              href="https://www.efs.sante.fr/la-transfusion-sanguine-de-1670-nos-jours"
              target="_blank"
              rel="noreferrer"
            >
              https://www.efs.sante.fr/la-transfusion-sanguine-de-1670-nos-jours
            </a>{" "}
            (page consultée le 21 décembre 2021)
          </p>
          <p>
            <sup>3</sup> Pavenski, Katerina et al. “Red blood cell storage
            lesions and related transfusion issues: a Canadian Blood Services
            research and development symposium.” Transfusion medicine reviews
            vol. 26,1 (2012): 68-84. doi:10.1016/j.tmrv.2011.07.003
          </p>
          <p>
            <sup>4</sup>Mollison, (2000), THE INTRODUCTION OF CITRATE AS AN
            ANTICOAGULANT FOR TRANSFUSION AND OF GLUCOSE AS A RED CELL
            PRESERVATIVE. British Journal of Haematology, 108: 13-18.{" "}
            <a
              href="https://www.ifpbm.org/knowledge/the-history-of-patient-blood-management"
              target="_blank"
              rel="noreferrer"
            >
              https://www.ifpbm.org/knowledge/the-history-of-patient-blood-management
            </a>{" "}
          </p>
          <p>
            <sup>5</sup>Rous, P, and J R Turner. “THE PRESERVATION OF LIVING RED
            BLOOD CELLS IN VITRO : I. METHODS OF PRESERVATION.” The Journal of
            experimental medicine vol. 23,2 (1916): 219-37.
            doi:10.1084/jem.23.2.219
          </p>
          <p>
            <sup>6</sup>Prowse, C V et al. “Commercially available blood storage
            containers.” Vox sanguinis vol. 106,1 (2014): 1-13.
            doi:10.1111/vox.12084
          </p>
          <p>
            <sup>7</sup>Evatt BL. The tragic history of AIDS in the hemophilia
            population, 1982–1984. J Thromb Haemost. 2006;4(11):2295-2301.
          </p>
          <p>
            <sup>8</sup>Leveton LB et al. Institute of Medicine (US) Committee
            to Study HIV Transmission Through Blood and Blood Products , Janvier
            2019, 10.17226/4989
          </p>
          <p>
            <sup>9</sup>Cooley. D, International Foundation for Patient Blood
            Management - The History of Patient Blood Management, Sur le site de
            l’International Foundation for Patient Blood Management [En ligne],{" "}
            <a
              href="https://www.ifpbm.org/knowledge/the-history-of-patient-blood-management"
              target="_blank"
            >
              https://www.ifpbm.org/knowledge/the-history-of-patient-blood-management
            </a>{" "}
            (page consultée le 27 décembre 2021)
          </p>
          <p>
            <sup>10</sup>Organisation Mondiale de la Santé (OMS), VIH et SIDA,
            Sur le site de l’OMS [En ligne],{" "}
            <a
              href="https://www.who.int/fr/news-room/fact-sheets/detail/hiv-aids"
              target="_blank"
            >
              https://www.who.int/fr/news-room/fact-sheets/detail/hiv-aids
            </a>{" "}
            (page consultée le 27 Mai 2022)
          </p>

          <p>
            <sup>11</sup>Alain Beauplet et al., La médecine transfusionnelle :
            le modèle français, Sur le site de John Libbey Eurotext [En ligne],{" "}
            <a
              href="https://www.jle.com/fr/ouvrages/e
docs/la_medecine_transfusionnelle_le_modele_francais_297063/ouvrage.phtml#:~:text=La%20 m%C3%A9decine%20 transfusionnelle%20fran%C3%A7aise%20se,regard%20des%20 autres%20mod%C3%A8les%20 internationaux%20%3A&text=le%20 don%20du%20sang%20 repose,le%20 socle%20 du %20mod%C3%A8le%20fran%C3%A7ais."
              target="_blank"
            >
              https://www.jle.com/fr/ouvrages/e
              docs/la_medecine_transfusionnelle_le_modele_francais_297063/ouvrage.phtml#:~:text=La%20
              m%C3%A9decine%20
              transfusionnelle%20fran%C3%A7aise%20se,regard%20des%20
              autres%20mod%C3%A8les%20 internationaux%20%3A&text=le%20
              don%20du%20sang%20 repose,le%20 socle%20 du
              %20mod%C3%A8le%20fran%C3%A7ais.
            </a>{" "}
            (page consultée le 27 Mai 2022)
          </p>
        </div>
      </section>
    </article>
  );
}
