// article is the main container
// must put section inside the article to add the margin bottom between sections
// div in the section will add margin to the left
// div.half_to_right will put the div to right in half size 50% to the parent
// ul and li still need a droplet for it but it work fines for now
// p will be just for breaking the line or paragraph
// b will be for bold
import { ReactComponent as PDF } from "../../../assets/images/pdf.svg";
import selection_des_donneurs_pdf from "../../../assets/pdf/selection_donneurs.pdf";


export function Slide1_1(props) {
  return (
    <article>
      <section>
        <p>
          <b>La sélection des donneurs pour une transfusion sanguine</b>
        </p>
        <p>
          Quatre règles éthiques régissent le don du sang en France :
          l’anonymat, seul l’EFS connaît l’identité des donneurs ; le bénévolat,
          aucune rémunération ne peut être versée aux donneurs<sup>1,2</sup> ;
          le consentement éclairé, le patient doit donner son accord après avoir
          reçu toutes les informations claires, complètes, compréhensibles et
          appropriées à la situation ; et le non profit<sup>1</sup>. Le don du
          sang doit respecter certaines règles de sécurité. Ces dernières
          répondent à des normes et à des critères dictés par des organismes
          internationaux, dont l’Union Européenne<sup>3</sup>.
        </p>
      </section>

      <section>
        <p>
          En France, les critères de sélection des donneurs de sang sont définis
          par un arrêté ministériel<sup>4</sup>. Ce dernier rappelle le fait
          qu’un médecin de l’Établissement Français du Sang (EFS) doit évaluer
          et apprécier, avec le donneur, les éventuelles contre-indications lors
          d’un entretien confidentiel "prédon"<sup>2,4</sup>.
        </p>
      </section>
      <section>

        <p>
          Il convient également de noter que les femmes peuvent donner leur sang jusqu’à 4 fois par an, les 
          hommes jusqu’à 6 fois par an. Dans tous les cas, un délai de 8 semaines minimum doit être respecté 
          entre deux dons<sup>2</sup>.
        </p>
      </section>
      <section>


        <p>Comment sont sélectionnés les donneurs de sang ?</p>
        <p>
          Afin d’assurer la sécurité des transfuseurs et des transfusés, en
          France, les donneurs doivent répondre aux exigences suivantes :
        </p>
        <ul>
          <li>Être âgés entre 18 et 70 ans</li>
          <li>Peser plus de 50 kg (volume de sang total ≥ 5L)</li>
          <li>
            Taux d’hémoglobine ≥ 12g/dL pour les femmes et 13g/dL pour les
            hommes sauf pour le don en aphérèse simple de globule rouge (≥
            14g/dL pour tous)
          </li>
          <li>
            Être reconnu apte lors de l’entretien confidentiel pré-don
            <sup>2,4</sup>
          </li>
        </ul>
      </section>

      <section>
        <p><b>Les contre-indications au don du sang</b></p>
        <p>
          En France, l’arrêté du 17 décembre 2019 définit une liste de
          contre-indications complémentaires devant entrainer l’ajournement du
          don<sup>4</sup>. Cette liste complémentaire a pour objectif de réduire
          les risques pour le donneur comme pour le receveur.
        </p>
        <p>
          S’il répond de l’une des conditions suivantes, un candidat au don
          pourra être refusé pour protéger sa santé :
        </p>
        <ul>
          <li>
            Toute altération de la santé du candidat (grave active, chronique, à
            rechute ou pathologie maligne) susceptible de menacer sa santé au
            moment du don est une contre-indication jusqu’à guérison ;
          </li>
          <li>
            Pour les pathologies liées à l’hémostase, coagulopathies, affections
            cardiovasculaires ou décompensation d’une affection neurologique (ex
            : AVC, AIT, convulsion, épilepsie…), la contre-indication est
            permanente (sauf pour les antécédents de convulsion infantiles et en
            l’absence de crise d’épilepsie après au moins 3 ans d’arrêt du
            traitement ;
          </li>
          <li>
            Si le don peut induire un risque d’incident hémodynamique (ex :
            traitement anti-hypertenseur, la TA SYS au repos {">"} 180 mmHg, TA
            DIA au repos {">"} à 100 mmHg, …) le don n’est possible que sur
            l’appréciation du médecin de l’établissement de transfusion sanguine
            ;
          </li>
          <li>
            La candidate est enceinte ou a accouché il y a moins de 7 mois ;
          </li>
          <li>
            Le volontaire à un historique d’asthme grave ou d’allergie pouvant
            aller jusqu’à une réaction anaphylactique à un allergène susceptible
            d’être utilisé lors du prélèvement. Sauf pour l’asthme, qui est une
            contre-indication permanente, dans les autres situations, le don est
            laissé à l’appréciation de la personne habilité à l’entretien
            pré-don.
          </li>
        </ul>
        <br></br>
        <p>
          D’autres contre-indications au don présentées dans l’annexe II de
          l’arrêté du 17 décembre 2019 visent à protéger le receveur de
          l’exposition à des médicaments :
        </p>
        <ul>
          <li>
            La consommation avérée d’un agent tératogène entraine un ajournement
            du don pouvant aller d’1 semaine (ex : lithium, carbimazole…) à 3
            ans (acitrétine) après arrêt du traitement selon le médicament
            référencé en annexe VII de l’arrêté (Tableau 1) ;
          </li>
          <li>
            La prise d’un traitement inhibant les fonctions plaquettaires
            reporte le don d’1 jour (pour les AINS) à 5 jours (pour l’acide
            acétyl salicylique) après l’arrêt du traitement ;
          </li>
        </ul>
      </section>
      <section>
        <p>
          Enfin, afin de limiter la transmission d’agents infectieux,
          l’entretien pré-don cherche aussi à identifier si le volontaire y a
          récemment été exposé (ex : vaccination, cas contact, voyages, lésions
          cutanées, antécédents d’infections, infections avérées, intervention
          médicale ou chirurgicale…) pour confirmer l’éligibilité au don du sang
          (Tableau 2).
        </p>
      </section>
      <section>
        <sub>
          Tableau 1. Dispositions relatives aux médicaments dont l'utilisation
          chez un candidat au don de sang entraine l'ajournement (Annexe VII de
          l’arrêté du 17 décembre 2019)
        </sub>
        <table style={{ width: "80%" }}>
          <tr style={{ background: "var(--background-color)", color: "white" }}>
            <th style={{ fontSize: "14px", width: "60%" }}>
              Médicament
              <br /> Dénomination commune internationale
            </th>
            <th style={{ fontSize: "14px" }}>
              Durée d'ajournement après la dernière prise
            </th>
          </tr>
          <tr>
            <td>Acétazolamide</td>
            <td>1 semaine</td>
          </tr>
          <tr>
            <td>Acitrétine</td>
            <td>3 ans</td>
          </tr>
          <tr>
            <td>Alitrétinoïne</td>
            <td>1 mois</td>
          </tr>
          <tr>
            <td>Baclofène</td>
            <td>1 semaine</td>
          </tr>
          <tr>
            <td>Carbamazépine</td>
            <td>1 semaine</td>
          </tr>
          <tr>
            <td>Carbimazole</td>
            <td>1 semaine</td>
          </tr>
          <tr>
            <td>Danazol</td>
            <td>1 semaine</td>
          </tr>
          <tr>
            <td>Isotrétinoïne</td>
            <td>1 mois</td>
          </tr>
          <tr>
            <td>Lithium (sels de)</td>
            <td>1 semaine</td>
          </tr>
          <tr>
            <td>Méthimazole (Thiamazol) </td>
            <td>1 semaine</td>
          </tr>
          <tr>
            <td>Méthotrexate </td>
            <td>1 semaine</td>
          </tr>
          <tr>
            <td>Modafinil </td>
            <td>1 semaine</td>
          </tr>
          <tr>
            <td>Mycophénolate mofetil (acide mycophénolique) </td>
            <td>6 semaine</td>
          </tr>
          <tr>
            <td>Raloxifène </td>
            <td>1 semaine</td>
          </tr>
          <tr>
            <td>Testostérone </td>
            <td>6 mois</td>
          </tr>
          <tr>
            <td>Thalidomide </td>
            <td>1 semaine</td>
          </tr>
          <tr>
            <td>Topiramate </td>
            <td>1 semaine</td>
          </tr>
          <tr>
            <td>
              Valpromide/ divalproate de sodium/ valproate de sodium/ acide
              valproïque{" "}
            </td>
            <td>1 semaine</td>
          </tr>
        </table>
      </section>
      <br></br>
      <section>
        <sub>
          Tableau 2. Extrait du tableau des contre-indications - Risques pour le
          receveur - Transmission d'agents infectieux (Annexe II de l’arrêté du
          17 décembre 2019)
        </sub>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr
              style={{ background: "var(--background-color)", color: "white" }}
            >
              <th style={{ fontSize: "14px", width: "20%" }}>RISQUE CIBLÉ</th>
              <th style={{ fontSize: "14px", width: "40%" }}>
                SITUATIONS A RISQUE
              </th>
              <th style={{ fontSize: "14px", width: "40%" }}>
                CONDUITE À TENIR (CAT) ET CONTRE INDICATIONS (CI) EN FONCTION
                DES REPONSES ET DE LA SITUATION A RISQUE
              </th>
            </tr>

            <tr>
              <td rowspan="4">Transmission d'un agent pathogène</td>
              <td>Vaccination par vaccins vivants atténués</td>
              <td>CI de quatre semaines</td>
            </tr>
            <tr>
              <td>Vaccination par vaccins inactivés</td>
              <td rowspan="2">
                Don autorisé si l'état de santé est satisfaisant.
              </td>
            </tr>
            <tr>
              <td>Vaccination par anatoxines</td>
            </tr>
            <tr>
              <td>Vaccination antirabique</td>
              <td>
                Don autorisé si l'état de santé est satisfaisant et en l'absence
                d'exposition au virus. CI d'un an si la vaccination est faite
                après l'exposition au virus
              </td>
            </tr>
            <tr>
              <td rowspan="16">Transmission d'un agent infectieux</td>
              <td rowspan="5">Contact avec un sujet infectieux</td>
              <td></td>
            </tr>
            <tr>
              <td>
                La personne habilitée à procéder à l'entretien pré-don apprécie
                la possibilité d'un don en fonction :
              </td>
            </tr>
            <tr>
              <td>
                -de la transmissibilité de l'agent pathogène par voie sanguine
              </td>
            </tr>
            <tr>
              <td>-de la durée d'incubation de l'infection</td>
            </tr>
            <tr>
              <td>-du délai passé depuis l'exposition</td>
            </tr>
            <tr>
              <td>Infection et/ ou fièvre {">"} 38° C </td>
              <td>CI de deux semaines après la disparition des symptômes</td>
            </tr>
            <tr>
              <td rowspan="3">Intervention chirurgicale</td>
              <td>
                La personne habilitée à procéder à l'entretien pré-don apprécie
                le caractère majeur ou mineur de l'intervention chirurgicale :
              </td>
            </tr>
            <tr>
              <td>
                -CI d'une semaine au minimum en cas d'intervention chirurgicale
                mineure
              </td>
            </tr>
            <tr>
              <td>CI de 4 mois en cas d'intervention chirurgicale majeure</td>
            </tr>
            <tr>
              <td>Corticothérapie par voie générale</td>
              <td>CI de deux semaines après arrêt du traitement</td>
            </tr>
            <tr>
              <td>Infection biologiquement avérée par le VIH, VHC, HTLV</td>
              <td>CI permanente</td>
            </tr>
            <tr>
              <td>
                Prophylaxie pré-exposition (PrEP) et prophylaxie post-exposition
                (PEP) au VIH
              </td>
              <td>CI de 4 mois après la dernière prise du traitement</td>
            </tr>
            <tr>
              <td rowspan="2">Infection par le VHB</td>
              <td>CI permanente</td>
            </tr>
            <tr>
              <td>
                Don de plasma pour fractionnement autorisé si Ag HBs négatif
                dont l'immunisation anti-HBs est démontrée
              </td>
            </tr>
            <tr>
              <td>Infections sexuellement transmissibles (IST)</td>
              <td>CI de quatre mois après guérison</td>
            </tr>
            <tr>
              <td>Syphilis</td>
              <td>CI d'un an après guérison</td>
            </tr>
            <tr>
              <td rowspan="9">Transmission d'un agent infectieux</td>
              <td rowspan="2">Infection par le Virus West Nile</td>
              <td>CI de 120 jours après la fin des symptômes.</td>
            </tr>
            <tr>
              <td>
                CI de 28 jours après avoir quitté une région présentant des cas
                au moment du séjour, de transmission du virus à l'homme, sauf si
                le dépistage génomique viral unitaire est réalisé et s'avère
                négatif
              </td>
            </tr>
            <tr>
              <td>Traitement antibiotique (hors acné simple)</td>
              <td>
                CI d'une semaine après arrêt du traitement et de deux semaines
                après la fin des symptômes
              </td>
            </tr>
            <tr>
              <td>Infection avérée à Yersinia-enterocolitica</td>
              <td>CI de six mois après guérison</td>
            </tr>
            <tr>
              <td rowspan="2">Soins dentaires</td>
              <td>
                Soins simples (soins de caries, détartrage, etc.) : CI d'1 jour
              </td>
            </tr>
            <tr>
              <td>
                Autres soins (traitement de racines, extraction dentaire) : CI
                d'une semaine et jusqu'à cicatrisation
              </td>
            </tr>
            <tr>
              <td>Lésions cutanées (eczéma …) au point de ponction </td>
              <td>CI jusqu'à guérison des lésions</td>
            </tr>
            <tr>
              <td>Plaie cutanée (ulcère variqueux, plaies infectées …) </td>
              <td>CI jusqu'à cicatrisation</td>
            </tr>
            <tr>
              <td>
                Antécédent de brucellose, d'ostéomyélite, de fièvre Q, de
                tuberculose et de rhumatisme articulaire aigu
              </td>
              <td>CI de deux ans après la date de guérison</td>
            </tr>
          </tbody>
        </table>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td rowspan="2" style={{ width: "20%", borderTop: "0px" }}>
                Transmission d'un agent infectieux par voie sexuelle <br></br>
                <i>Candidat au don</i>
              </td>
              <td rowspan="2" style={{ width: "20%", borderTop: "0px" }}>
              Risque d’exposition du 
              candidat au don à un agent 
              infectieux transmissible par 
              voie sexuelle
              </td>
              <td style={{ borderTop: "0px" }}>
              Rapport (s) sexuel (s) avec plus d'un partenaire dans les quatre derniers mois
              </td>
              <td style={{ width: "40%", borderTop: "0px" }}>
              CI de quatre mois après la fin de la situation considérée
              </td>
            </tr>
            <tr>
              <td>
              Rapport (s) sexuels (s) en échange d'argent ou de drogue
              </td>
              <td>
              CI de douze mois après la fin de la situation considérée
              </td>
            </tr>
            <tr>
              <td rowspan="8">
                Transmission d'un agent Infectieux par voie sexuelle <br />
                Partenaire
              </td>
              <td rowspan="8">
                Risque d'exposition du partenaire sexuel du candidat au don à un
                agent infectieux transmissible par voie sexuelle
              </td>
              <td>
              Partenaire ayant lui-même eu plus d'un partenaire sexuel dans les quatre derniers mois              </td>
              <td>
              CI de quatre mois après le dernier rapport sexuel considéré avec ce partenaire
              </td>
            </tr>
            <tr>
              <td>
              Partenaire ayant utilisé par voie injectable des drogues ou des substances dopantes sans prescription

            </td>
              <td>
              CI de douze mois après le dernier rapport sexuel considéré avec ce partenaire              </td>
            </tr>
            <tr>
              <td>
                Partenaire ayant eu un rapport sexuel en échange d'argent ou de
                drogue
              </td>
              <td>
                CI de douze mois après le dernier rapport sexuel considéré avec
                ce partenaire
              </td>
            </tr>
            <tr>
              <td>Partenaire ayant une sérologie positive pour :</td>
              <td>
                CI de douze mois après le dernier rapport sexuel considéré avec
                ce partenaire.
              </td>
            </tr>
            <tr>
              <td>VIH, HTLV, VHC, VHB</td>
              <td>
                Pas de CI lorsque chez le partenaire VHC +, la recherche ARN est
                négative depuis plus d'un an.
              </td>
            </tr>
            <tr>
              <td>(AgHBs +)</td>
              <td>
                Pas de CI en cas de partenaire VHB (AgHBs +) si le donneur est
                vacciné et que son immunité est démontrée (Ac anti-HBs positifs)
                à un titre protecteur
              </td>
            </tr>
            <tr>
              <td>Partenaire ayant eu une IST récente ou en traitement</td>
              <td>CI de quatre mois après la guérison du partenaire</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td rowspan="8">Transmission d'un agent infectieux</td>
              <td rowspan="8">
                Risque d'exposition du candidat au don à un virus transmissible
                autrement que par voie sexuelle
              </td>
              <td>
                Utilisation par voie injectable de drogues ou de substances
                dopantes sans prescription
              </td>
              <td>CI permanente</td>
            </tr>
            <tr>
              <td rowspan="4">
                Acupuncture, sclérose de varices, mésothérapie
              </td>
              <td></td>
            </tr>
            <tr>
              <td>CI de quatre mois.</td>
            </tr>
            <tr>
              <td></td>
            </tr>
            <tr>
              <td>Pas de CI si utilisation de matériel à usage unique</td>
            </tr>
            <tr>
              <td>Accident d'exposition au sang</td>
              <td>CI de quatre mois</td>
            </tr>
            <tr>
              <td>Tatouage, piercing (boucles d'oreilles incluses)</td>
              <td>CI de quatre mois</td>
            </tr>
            <tr>
              <td>Endoscopie avec instrument flexible</td>
              <td>CI de quatre mois</td>
            </tr>
          </tbody>
        </table>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ width: "20%", borderBottom: "0px" }} rowspan="4">
                Transmission du paludisme à partir d'une zone à risque (La zone
                à risque est définie comme non exempte de paludisme endémique,
                c'est-àdire tout pays ou région du pays pour lequel une autre
                mention que Absence de transmission du paludisme figure dans la
                liste établie par le Haut Conseil de santé Publique
              </td>
              <td style={{ width: "40%" }} rowspan="2">
                Antécédent de paludisme avéré ou de sérologie positive connue{" "}
              </td>
              <td style={{ width: "40%" }}>
                CI de trois ans après la fin du traitement
              </td>
            </tr>
            <tr>
              <td>
                Après trois ans, don autorisé en l'absence de symptômes si test
                sérologique négatif au premier don
              </td>
            </tr>
            <tr>
              <td>Retour d'une zone à risque depuis moins de quatre mois </td>
              <td>CI de quatre mois après le retour</td>
            </tr>
            <tr>
              <td>
                Fièvre non diagnostiquée évocatrice d'un accès palustre dans les
                4 mois suivant le retour d'une zone endémique
              </td>
              <td>
                CI de quatre mois après la fin des symptômes puis don autorisé
                si test sérologique négatif au premier don.
              </td>
            </tr>
          </tbody>
        </table>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td
                rowspan="3"
                style={{ width: "20%", borderTop: "0px", borderBottom: "0px" }}
              ></td>
              <td rowspan="3" style={{ width: "20%", borderTop: "0px" }}>
                Retour d'une zone à risque depuis plus de quatre mois et moins
                de trois ans
              </td>
              <td style={{ borderTop: "0px" }}>
                Personne née ou ayant vécu en zone à risque au cours de ses cinq
                premières années
              </td>
              <td style={{ width: "40%", borderTop: "0px" }}>
                Don autorisé en l'absence de symptômes si test sérologique
                négatif à chaque don pendant cette période
              </td>
            </tr>
            <tr>
              <td>
                Personnes ayant séjourné ou voyagé plus de 6 mois consécutifs en
                zone à risque
              </td>
              <td>
                Don autorisé en l'absence de symptômes si test sérologique
                négatif à chaque don pendant cette période de 3 ans après le
                retour
              </td>
            </tr>
            <tr>
              <td>
                Personnes ayant séjourné ou voyagé moins de 6 mois en zone à
                risque
              </td>
              <td>
                Don autorisé en l'absence de symptômes si test sérologique
                négatif au premier don
              </td>
            </tr>
            <tr>
              <td rowspan="2" style={{ width: "20%", borderTop: "0px" }}></td>
              <td rowspan="2" style={{ width: "20%", borderTop: "0px" }}>
                Retour depuis plus de trois ans
              </td>
              <td style={{ borderTop: "0px" }}>
                Personne née ou ayant vécu en zone à risque au cours de ses cinq
                premières années
              </td>
              <td style={{ width: "40%", borderTop: "0px" }}>
                Don autorisé en l'absence de symptômes si test sérologique
                négatif au premier don
              </td>
            </tr>
            <tr>
              <td>
                Personnes ayant séjourné ou voyagé plus de 6 mois en zone à
                risque
              </td>
              <td>
                Don autorisé en l'absence de symptômes si test sérologique
                négatif au premier don
              </td>
            </tr>
          </tbody>
        </table>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ width: "20%", borderBottom: "0px" }} rowspan="3">
                Transmission de trypanosomiase américaine (maladie de Chagas)
              </td>
              <td style={{ width: "40%" }}>Antécédent de maladie de Chagas</td>
              <td style={{ width: "40%" }}>CI permanente</td>
            </tr>
            <tr>
              <td>
                Naissance, résidence, séjour, quelles que soient la durée et la
                date, en zone endémique
              </td>
              <td>
                CI temporaire de quatre mois après le retour. Puis don autorisé
                si test sérologique négatif au premier don
              </td>
            </tr>
            <tr>
              <td>
                Mère née en Amérique du Sud, en Amérique centrale ou au Mexique{" "}
              </td>
              <td>Don autorisé si test sérologique négatif au premier don</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td style={{ width: "20%", borderBottom: "0px" }} rowspan="2">
                Transmission d'une autre infection parasitaire
              </td>
              <td style={{ width: "40%" }}>
                Antécédent de babésiose (*), de Kala Azar (leishmaniose
                viscérale) (*){" "}
              </td>
              <td style={{ width: "40%" }}>CI permanente</td>
            </tr>
            <tr>
              <td>Antécédent de toxoplasmose (*)</td>
              <td>CI de six mois après la date de guérison complète</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td rowspan="6">
                Transmission d'une encéphalopathie spongiforme subaiguë
                transmissible (ESST). Exemple : MCJ, vMCJ
              </td>
              <td>
                Antécédent familial d'ESST qui expose le donneur au risque de
                développer une ESST
              </td>
              <td>CI permanente</td>
            </tr>
            <tr>
              <td>Intervention neurochirurgicale et ophtalmologique</td>
              <td>
                CI permanente pour toute intervention antérieure au 1er avril
                2001
              </td>
            </tr>
            <tr>
              <td>Greffe de dure-mère ou de cornée</td>
              <td>CI permanente</td>
            </tr>
            <tr>
              <td>Traitement par extraits hypophysaires avant 1989 </td>
              <td>CI permanente</td>
            </tr>
            <tr>
              <td>
                Traitement par glucocérébrosidase placentaire de la maladie de
                Gaucher
              </td>
              <td>CI permanente</td>
            </tr>
            <tr>
              <td>
                Voyage et/ ou séjours au Royaume-Uni {">"} 1an cumulé dans la
                période du 1er janvier 1980 au 31 décembre 1996
              </td>
              <td>CI permanente</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td rowspan="4">
                Transmission d'un agent pathogène inconnu (principe de
                précaution)
              </td>
              <td>
                Transmission d'une substance à risque d'anaphylaxie : Recherche
                d'un traitement par désensibilisation
              </td>
              <td>CI de trois jours</td>
            </tr>
            <tr>
              <td>
                Transfusion de cellules sanguines fonctionnellement anormales :
                drépanocytose homozygote, déficits enzymatiques de GR,
                polyglobulie essentielle, porphyrie aiguë, thalassémie majeure
              </td>
              <td>CI permanente</td>
            </tr>
            <tr>
              <td>Antécédent de transfusion sanguine</td>
              <td rowspan="2">CI permanente</td>
            </tr>
            <tr>
              <td>Antécédent d'allogreffe ou de xénogreffe</td>
            </tr>
          </tbody>
        </table>
      </section>
      <br />
      <figure className="half_to_right">
        <div style={{ width: "100px" }}>
          <a href={selection_des_donneurs_pdf} target="_blank" rel="">
            <PDF />
          </a>
        </div>
        <figcaption className="caption">
          {" "}
          Infographie - Sélection des donneurs
        </figcaption>
      </figure>

      <br />
      <section>
        <p>
          {" "}
          <div style={{ marginLeft: "0rem" }}>
            <p>
              <sup>1</sup>Santé Publique France. “Don de sang.” Santé publique
              France, 14 Janvier 2022,
              <a
                href="https://www.santepubliquefrance.fr/don-de-sang/les-enjeux-de-sante/#tabs"
                target="_blank"
              >
                https://www.santepubliquefrance.fr/don-de-sang/les-enjeux-de-sante/#tabs
              </a>{" "}
              - (Consulté le 1er Juin 2022).
            </p>
            <p>
              <sup>2</sup>Direction de l'information légale et administrative.
              “Don du sang | service-public.fr.” Service public, 16 Mars 2022,
              <a
                href="https://www.service-public.fr/particuliers/vosdroits/F2376"
                target="_blank"
              >
                https://www.service-public.fr/particuliers/vosdroits/F2376
              </a>{" "}
              (Consulté le 13 Juillet 2022).
            </p>
            <p>
              <sup>3</sup>Établissement Français du Sang. “L'EFS, un rôle à
              jouer dans la révision des directives européennes sur le sang, les
              tissus et les cellules.” l'Etablissement français du sang, 27
              April 2022,
              <a
                href="https://www.efs.sante.fr/lefs-un-role-jouer-dans-la-revision-des-directives-europeennes-sur-le-sangles-tiss us-et-les"
                target="_blank"
              >
                https://www.efs.sante.fr/lefs-un-role-jouer-dans-la-revision-des-directives-europeennes-sur-le-sangles-tiss
                us-et-les
              </a>{" "}
              . (Consulté le 13 Juillet 2022).
            </p>
            <p>
              <sup>4</sup>Ministère des solidarités et de la santé. “Arrêté du
              17 décembre 2019 fixant les critères de sélection des donneurs de
              sang.” Légifrance, 17 décembre 2019
              <a
                href="https://www.legifrance.gouv.fr/loda/id/JORFTEXT000039667225/"
                target="_blank"
              >
                https://www.legifrance.gouv.fr/loda/id/JORFTEXT000039667225/
              </a>{" "}
              (Consulté le 31 Mai 2022).
            </p>
          </div>
        </p>
      </section>

      {/* <section>Article disponible prochainement.</section> */}
    </article>
  );
}
