// article is the main container
// must put section inside the article to add the margin bottom between sections
// div in the section will add margin to the left
// div.half_to_right will put the div to right in half size 50% to the parent
// ul and li still need a droplet for it but it work fines for now
// p will be just for breaking the line or paragraph
// b will be for bold
import { ReactComponent as PDF } from "../../../assets/images/pdf.svg";
import prd_delie_son_pdf from "../../../assets/pdf/BAT_Infographie_Produits_dérivés_du_sang MKT-EJC.pdf";

export function Slide1_4(props) {
  return (
    <article>
      <section>
        <p>
          <b>Les médicaments dérivés du sang</b>
        </p>
        <p>
          Les produits dérivés du sang correspondent à des produits composés de
          sang ou de composants de sang, préparés de façon industrielle. Ils
          doivent faire l’objet d’une Autorisation de Mise sur le Marché (AMM)
          afin de pouvoir être commercialisés<sup>1</sup>.
        </p>
      </section>
      <section>
        <p>
          <b>Les catégories de produits dérivés du sang</b>
        </p>
        <p>
          Les produits dérivés du sang doivent répondre à la réglementation des
          médicaments les concernant et à des règles de traçabilité fixées dans
          le décret n°95-566 du 6 mai 1995<sup>2</sup>:
        </p>
      </section>
      <section>
        <p>
          <b>L'albumine</b>
        </p>
        <p>
          L&#39;albumine est la protéine la plus abondante du plasma sanguin.
          Elle est synthétisée par le foie, responsable du transport d’éléments
          essentiels dans le sang. Elle est également importante dans le
          maintien de la pression oncotique (grandeur exprimant le degré de
          facilité avec lequel des protéines en solution dans un fluide attirent
          l&#39;eau)<sup>3</sup> du plasma. Elle peut être utilisée sous forme
          iso-oncotique (4-5%) ou hyper-oncotique (20%). Ce soluté de
          remplissage de type colloïde est utilisé pour restaurer et maintenir
          le volume sanguin circulant<sup>4</sup>.
        </p>
      </section>
      <section>
        <p>
          <b>Les immunoglobulines</b>
        </p>
        <p>
          Les immunoglobulines (Ig) sont sécrétées par les plasmocytes, en
          réponse à la pénétration d’un élément étranger (antigène) dans
          l’organisme. &Agrave; la naissance, l’être humain possède déjà des
          immunoglobulines maternelles qui ont traversé le placenta. Avec le
          temps, le système immunitaire se développe suivant les antigènes
          rencontrés. Les immunoglobulines participent à la défense immunitaire
          de l’organisme et possèdent un rôle dans la régulation de l’immunité
          <sup>5</sup>.
        </p>
      </section>
      <section>
        <p>
          Les immunoglobulines humaines normales sont utilisées en thérapeutique
          substitutive et font l’objet de nombreuses études cliniques pour la
          validation de nouvelles indications dans les maladies auto-immunes.
          Rares et longues à produire, leur usage est limité à deux indications
          <sup>4</sup>:
          <ul>
            <li>la substitution d’un déficit immunitaire</li>
            <li>
              le traitement immunomodulateur des maladie auto-immunes et/ou
              inflammatoires
            </li>
          </ul>
        </p>
      </section>
      <section>
        <p>
          <b>Les facteurs de coagulation</b>
        </p>
        <p>
          Les facteurs de coagulation jouent un rôle prépondérant dans la
          coagulation sanguine. Ces derniers, qui sont produits dans le foie,
          sont, dans leur majorité, des molécules qui circulent dans le sang
          <sup>6</sup>.
        </p>
        <br></br>
        <p>
          Il existe différents facteurs de la coagulations: facteurs
          antihémophiliques, facteurs Willebrand, CCP (concentrés de complexe
          prothrombique ou PPSB), fibrinogène, facteurs VII, IX, X, XI et XIII.
          Dans cette catégorie on trouve également des fractions coagulantes
          issues du génie génétique (facteurs VIII, IX, VII activé), on parle
          alors d’analogues recombinants qui présentent des caractéristiques
          similaires aux caractéristiques des protéines natives. On retrouve
          également un anticorps monoclonal humanisé qui mime l’action du
          facteur VIIIa. Ces facteurs sont indiqués afin de suppléer un déficit
          innée ou acquis en prévention ou traitement d&#39;accidents
          hémorragiques<sup>4</sup>.
        </p>
      </section>
      <section>
        <p>
          <b>Les inhibiteurs de coagulation</b>
        </p>
        <p>
          Les inhibiteurs de coagulation sont des molécules présentes dans le
          plasma, qui ont pour but de réguler la coagulation en limitant la
          génération de thrombine.<sup>7</sup> Il n&#39;existe que deux
          médicaments dérivés du sang avec cette fonction d&#39;inhibiteur
          physiologique de la coagulation: l&#39;anti-thrombine et la protéine C
          <sup>4</sup>.
        </p>
        <p>
          L’antithrombine est un inhibiteur physiologique majeur de la
          coagulation sanguine. Elle exerce un effet d’inhibition sur l’activité
          de nombreux facteurs de la coagulation. Sa présence est indispensable
          à l’activité anticoagulante de l’héparine. Elle est utilisée dans le
          traitement de déficits acquis sévères et de déficits constitutionnels.
        </p>
        <p>
          La protéine C est un inhibiteur physiologique de la coagulation
          sanguine. Activée par la thrombine au niveau de l’endothélium
          vasculaire et associée à son cofacteur, la protéine S stimule
          l’inactivation protéolytique de plusieurs facteurs de la coagulation.
          Elle est utilisée en traitement substitutif de déficits
          constitutionnels sévères, exceptionnels, qui s’accompagnent de
          thromboses veineuses profondes<sup>4</sup>.
        </p>
      </section>
      <section>
        <p>
          <b>
            Les inhibiteurs de protéases<sup>4</sup>
          </b>
        </p>
        <p>
          Les inhibiteurs de protéases sont des inhibiteurs physiologiques
          impliqués dans la régulation des épisodes inflammatoires. Il
          n&#39;existe que deux médicaments dérivés du sang avec cette fonction
          : l&#39;inhibteur de C1-estérase et l&#39;alpha-1 anti-trypsine.
        </p>
        <p>
          L’inhibiteur de C1-estérase (C1- INH) est une protéine plasmatique.
          Son déficit quantitatif ou fonctionnel entraîne une maladie génétique
          rare appelée angioedème héréditaire. Deux variants de cette maladie
          sont décrits: le type 1 est lié à un défaut de synthèse de C1-INH et
          le type II est lié à la synthèse d’un C1-INH non fonctionnel.
        </p>
        <p>
          L’alpha-1 antitrypsine est une glycoprotéine hépatique dont la
          fonction est l’inhibition d’enzymes protéolytiques (principalement
          l’élastase). Le déficit en alpha-1 antitrypsine qui résulte d’une
          mutation génétique ponctuelle est une maladie génétique autosomique
          récessive. Ce déficit diminue la capacité de l’organisme à lutter
          contre les enzymes protéolytiques lors d’épisodes inflammatoires.
        </p>
      </section>
      <section>
        <p>
          <b>
            Les colles biologiques<sup>4</sup>
          </b>
        </p>
        <p>
          Les colles biologiques sont exclusivement réservées à un usage local,
          elles sont utilisées en chirurgie pour leurs propriétés hémostatiques
          et adhésives. En fonction de leurs indications, contre-indications et
          des interventions, les colles biologiques et autres hémostatiques
          chirurgicaux (classés en dispositifs médicaux) sont appliquées par le
          chirurgien pour:
        </p>
        <ul>
          <li>améliorer l’hémostase ou la cicatrisation</li>
          <li>renforcer les sutures</li>
          <li>permettre l’adhérence de tissus distincts</li>
          <li>assurer l’étanchéité</li>
          <li>
            un traitement adjuvant lorsque les techniques conventionnelles per
            opératoires apparaissent insuffisantes.
          </li>
        </ul>
      </section>
      <figure className="half_to_right">
        <div style={{ width: "100px" }}>
          <a href={prd_delie_son_pdf} target="_blank" rel="">
            <PDF />
          </a>
        </div>
        <figcaption className="caption">
          {" "}
          Infographie - Les produits dérivés du sang
        </figcaption>
      </figure>

      <br></br>
      <section>
        <div style={{ marginLeft: "0rem" }}>
          <p>
            <sup>1</sup>Glossaire: Médicament dérivé du sang, sur le site de
            l’ANSM, [en ligne],{" "}
            <a
              href="https://archiveansm.integra.fr/Produits-de-sante/Medicaments-derives-du-sang#:~:text=Les%20m%C3%A9dicaments%20d%C3%A9riv%C3%A9s%20du%20sang,des%20immunoglobulines%20d'origine%20humaine"
              target="_blank"
            >
              https://archiveansm.integra.fr/Produits-de-sante/Medicaments-derives-du-sang#:~:text=Les%20m%C3%A9dicaments%20d%C3%A9riv%C3%A9s%20du%20sang,des%20immunoglobulines%20d'origine%20humaine
            </a>{" "}
            (page consultée le 17 novembre 2022)
          </p>
          <p>
            <sup>2</sup>Legifrance. “Paragraphe 2: Suivi des médicaments dérivés
            du sang (Articles ...” Légifrance, 1 December 1995,{" "}
            <a
              href="https://www.legifrance.gouv.fr/codes/id/LEGIARTI000006800622/1995-12-01"
              target="_blank"
            >
              https://www.legifrance.gouv.fr/codes/id/LEGIARTI000006800622/1995-12-01
            </a>{" "}
            Accessed 25 July 2022
          </p>
          <p>
            <sup>3</sup>Larousse. Encyclopédie médicale, définition de la
            pression oncotique,{" "}
            <a
              href="https://www.larousse.fr/encyclopedie/medical/pression_oncotique/15518"
              target="_blank"
            >
              https://www.larousse.fr/encyclopedie/medical/pression_oncotique/15518
            </a>{" "}
          </p>
          <p>
            <sup>4</sup>PERMEDES. Les médicaments dérivés du plasma et les
            recombinants associés. 16, 2022. SFPC, Société Française de
            Pharmacie Clinique,{" "}
            <a href="https://sfpc.eu/permedes/" target="_blank">
              https://sfpc.eu/permedes/
            </a>{" "}
            Accessed 01 Août 2022.
          </p>
          <p>
            <sup>5</sup>Immunoglobuline, le sur site du Larousse.fr, [En ligne],{" "}
            <a
              href="https://www.larousse.fr/encyclopedie/medical/immunoglobuline/13818"
              target="_blank"
            >
              https://www.larousse.fr/encyclopedie/medical/immunoglobuline/13818
            </a>
            ,{" "}(page consultée le 27 décembre 2021)
          </p>
          <p>
            <sup>6</sup>Piquard, Laurence. “L’hémostase, une incroyable mosaïque
            de réactions ordonnées !” accusions, 24 Mai 2019,{" "}
            <a
              href="https://www.actusoins.com/313263/hemostase-une-incroyable-mosaique-de-reactions-ordonnees.html?_ga=2.174407076.765786035.1658825147-1222998540.1658825147"
              target="_blank"
            >
              https://www.actusoins.com/313263/hemostase-une-incroyable-mosaique-de-reactions-ordonnees.html?_ga=2.174407076.765786035.1658825147-1222998540.1658825147
            </a>{" "}
            Accessed 26 July 2022.
          </p>
          <p>
            <sup>7</sup>F.Fourrier, Inhibiteurs de coagulation et états
            infectieux graves, Réanimation, juin 2008, v17, p370- 378, Doi:
            10.1016/j.reaurg.2008.03.013
          </p>
        </div>
      </section>
    </article>
  );
}
