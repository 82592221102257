// article is the main container
// must put section inside the article to add the margin bottom between sections
// div in the section will add margin to the left
// div.half_to_right will put the div to right in half size 50% to the parent
// ul and li still need a droplet for it but it work fines for now
// p will be just for breaking the line or paragraph
// b will be for bold
export function Slide2_2(props) {
  return (
    <article>
      <section>
        <p>
          Les transfusions sanguines peuvent donner lieu à des évènements
          indésirables aigus ou retardés graves. La connaissance de ces
          réactions transfusionnelles et la capacité à anticiper les formes
          graves de celles-ci mènent à un meilleur pronostic pour les patients
          transfusés.<sup>1</sup> Les expériences de mise en oeuvre progressive
          des programmes de Patient Blood Management ont montré que leur
          déploiement est tout à fait possible et efficace tant en termes de
          limitation des effets indésirables que de reprises chirurgicales, tout
          en étant associé à une diminution :
        </p>
        <ul>
          <li>
            Diminution de la morbidité postopératoire (infections, insuffisance
            rénale, pneumopathies, infarctus du myocarde/AVC, par exemple),
            voire de mortalité peropératoire ou hospitalière
          </li>
          <li>
            Augmentation de la proportion de patients non anémiés opérés en
            chirurgie réglée, de la récupération de sang autologue, ainsi que de
            la proportion de patients transfusés par un CGR unique
          </li>
          <li>
            Diminution des seuils transfusionnels, des pertes sanguines
            peropératoires, du taux de transfusions massives, de la proportion
            de patients transfusés, du nombre de CGR ou d’unités de produits
            sanguins labiles (PSL) transfusés par patient, de la proportion de
            patients sortant de l’hôpital avec une anémie modérée à sévère
          </li>
          <li>
            Diminution des durées moyennes de séjour et des coûts
            d’hospitalisation<sup>2</sup>
          </li>
        </ul>
      </section>
      <section>
        <p>
          <b>
            Quels sont les risques liés à la transfusion ?<sup>1</sup>
          </b>
        </p>
        <p>
          On distingue les risques aigus (survenant {"<"}24h après la
          transfusion) des risques retardés (survenant {">"}24 heures après la
          transfusion), qu’ils soient d'origine immunitaire ou non-immunitaire.
        </p>
        <p>
          Vous trouverez ci-dessous le détail de ces réactions ainsi que la
          conduite à adopter à chaque situation, selon les recommandations du
          Technical Manual AABB.
        </p>
      </section>
      <section>
        <b>
          Les réactions aiguës d’origine immunitaire<sup>1</sup>
        </b>

        <p>
          <br />
          Réaction hémolytique aiguë : elle résulte d’une incompatibilité entre
          les globules rouges du donneur et du receveur et cause des symptômes
          tels que de la fièvre, des frissons, des douleurs dorsales, une
          hypotension, une hémoglobinurie, des douleurs au site d’injection, des
          diathèses hémorragiques (coagulopathie). <br />
          Conduite à adopter : il faut arrêter la transfusion, maintenir un
          débit urinaire {">"}1mL/kg/h et utiliser par intraveineuse des
          diurétiques, des analgésiques, de la dopamine à faible dose en cas
          d’hypotension et des produits dérivés du sang en cas de saignement.
        </p>
      </section>
      <section>
        <p>
          Urticaire : les IgE du receveur réagissent avec les protéines
          plasmatiques du donneur et conduisent à la libération de médiateurs
          par les mastocytes entraînant des prurits, de l’urticaire, des
          bouffées vasomotrices.
          <br /> Conduite à adopter : on traite ou prévient l’urticaire avec des
          anti-histaminiques.
        </p>
      </section>
      <section>
        <p>
          Réaction fébrile non hémolytique : elle est due à la présence de
          cytokines dans le sang qui se manifeste par une augmentation de la
          température {">"}1°C, des frissons, des rigidités, une sensation de
          malaise, des vomissements, des bouffées vasomotrices.
          <br /> Conduite à adopter : on utilise des antipyrétiques (sauf
          aspirine) pour baisser la température, de la mépéridine pour lutter
          contre les rigidités et des composants sanguins leuco-filtrés sont
          utilisés pour prévenir cette réaction.
        </p>
      </section>{" "}
      <section>
        <p>
          Réaction anaphylactique : les anticorps du receveur réagissent face
          aux protéines du donneur et entraînent une hypotension, de
          l’urticaire, des bronchospasmes, un stridor (bruit anormal, plus ou
          moins strident, émis par la respiration3), un œdème local.
          <br /> Conduite à adopter : selon la gravité de la crise, on utilise
          de l’adrénaline, des antihistaminiques, des corticoïdes et des
          composants sanguins filtrés et déplétés en IgA.
        </p>
      </section>
      <section>
        <p>
          Lésions pulmonaires aiguës : les anticorps leucocytaires du donneur ou
          du receveur entraînent une hypoxémie, un œdème pulmonaire
          non-cardiogénique, des difficultés respiratoires, une hypotension, de
          la fièvre, une cyanose. <br />
          Conduite à adopter : la prise en charge de ces lésions va de la mise
          sous oxygène à l'assistance respiratoire du patient.
        </p>
      </section>
      <section>
        <p>
          <b>
            Les réactions aiguës d’origine non-immunitaire<sup>1</sup>
          </b>
        </p>
      </section>
      <section>
        <p>
          Sepsis : le sang transfusé est contaminé par une bactérie qui entraîne
          chez le patient de la fièvre, des frissons, une hypotension dans les
          90 minutes suivant la transfusion. <br />
          Conduite à adopter : il faut traiter l’infection par une
          antibiothérapie adaptée afin d’inactiver l’agent pathogène et traiter
          les symptômes du choc.
        </p>
      </section>
      <section>
        <p>
          Hémolyse non-immunologique : il s’agit d’une destruction physique,
          mécanique ou chimique des globules rouges entraînant une
          hémoglobinurie ou une hémoglobinémie (hémolyse intra-vasculaire).
          <br />
          Conduite à adopter : dans ce cas, on a recours à un traitement
          symptomatique.
        </p>
      </section>
      <section>
        <p>
          Surcharge circulatoire : la transfusion provoque une surcharge
          volémique chez certains patients se manifestant par des signes
          d'insuffisance cardiaque congestive, un essoufflement, une respiration
          sifflante, une hypertension. <br />
          Conduite à adopter : il est nécessaire d’établir une surveillance
          attentive des débits de transfusion et de traiter ces symptômes par
          des diurétiques, une oxygénothérapie, une phlébotomie.
        </p>
      </section>
      <section>
        <p>
          Embolie gazeuse : causée par l’introduction d’air dans un vaisseau
          sanguin, elle entraîne une dyspnée soudaine, une cyanose aiguë, des
          douleurs scapulaires ou dorsales, une toux, une hypotension.
        </p>

        <p>
          Conduite à adopter : on place le patient en décubitus gauche pour
          éliminer la bulle d’air. La prévention passera par l’aspiration de
          l’air hors du matériel IV ou son pré-remplissage par une solution de
          charge.
        </p>
      </section>
      <section>
        <p>
          <b>
            Les réactions retardées d’origine immunitaire<sup>1</sup>
          </b>
        </p>
        <p>
          Réaction hémolytique retardée : il s’agit d’une réaction anamnestique
          aux antigènes des globules rouges qui entraîne de la fièvre, une
          baisse de l’hématocrite, un léger ictère et les signes d’une hémolyse
          (hémoglobinurie, hémoglobinémie).
          <br /> Conduite à adopter : pour prévenir cet effet secondaire, il
          faut réaliser une épreuve de comptabilité croisée ou crossmatch pour
          vérifier la compatibilité sanguine entre le patient et le donneur.
        </p>
      </section>
      <section>
        <p>
          Allo-immunisation aux antigènes de globules rouges, des plaquettes et
          des leucocytes : une incompatibilité antigénique interindividuelle
          peut provoquer une réponse sérologique retardée, un état réfractaire.
          En obstétrique, elle se manifeste par une maladie hémolytique du fœtus
          ou du nouveau-né.
          <br /> Conduite à adopter : cette allo-immunisation est prévenue par
          l'utilisation rationalisée de sang leucofiltré ou de produits dérivés
          du sang.
        </p>
      </section>
      <section>
        <p>
          Immunomodulation : les leucocytes allogènes ou leurs produits solubles
          peuvent être associés à un risque accru d’infections post-opératoires,
          de récidives de cancer, de défaillance multiviscérale.
          <br /> Conduite à adopter : ce risque est contrôlé par l'utilisation
          de sang leucofiltré ou de sang autologue.
        </p>
      </section>
      <section>
        <p>
          Maladie du greffon contre l’hôte (GVHD ou Graft-vs-host disease) :
          elle résulte de la multiplication des lymphocytes du donneur chez
          l’hôte entraînant une destruction de ses tissus. Cela se traduit par
          des éruptions cutanées, des diarrhées aqueuses, de la fièvre, une
          anorexie, des vomissements, une dysfonction hépatique, une
          insuffisance médullaire.
          <br /> Conduite à adopter : le traitement requiert des
          corticostéroïdes, des agents cytotoxiques. Malgré une occurrence rare,
          le taux de mortalité de cette maladie est de 90%, c’est pourquoi
          l’accent est mis sur la prévention par irradiation des composants
          cellulaires sanguins.
        </p>
      </section>
      <section>
        <p>
          Purpura post-transfusionnel : les anticorps se dirigent contre les
          antigènes spécifiques des plaquettes induisant une chute de la
          numération plaquettaire, cela mène à une thrombocytopénie, un purpura,
          des saignements.
          <br /> Conduite à adopter : on traite ce purpura avec des stéroïdes,
          des immunoglobulines par intraveineuse ou en évitant la transfusion du
          culot plaquettaire.
        </p>
      </section>
      <section>
        <p>
          <b>
            Les réactions retardées d’origine non-immunitaire<sup>1</sup>
          </b>
        </p>
        <p>
          Surcharge en fer : elle résulte d’un dépôt de fer chez les patients
          multi-transfusés et mène à du diabète, des cardiomyopathies, une
          cirrhose.
          <br /> Conduite à adopter : on régule cette surcharge par
          l’utilisation d'agents chélatants.
        </p>
      </section>
      <br />
      <section>
        <div style={{ marginLeft: "0rem" }}>
          <p>
            <sup>1</sup>S. Sahu, adverse events related to blood transfusion
            2014{" "}
            <a
              href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4260299/"
              target="_blank"
            >
              https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4260299/
            </a>{" "}
          </p>
          <p>
            <sup>2</sup>X. Capdevila Livre blanc du patient blood management
            2018,{" "}
            <a
              href="http://colloque-pbm.com/wp-content/uploads/2018/12/Livre_Blanc_PBM.pdf"
              target="_blank"
            >
              http://colloque-pbm.com/wp-content/uploads/2018/12/Livre_Blanc_PBM.pdf
            </a>{" "}
          </p>
          <p>
            <sup>3</sup>CHUV unité des voies aériennes, définition stridor{" "}
            <a
              href="https://www.chuv.ch/fr/voies-aeriennes/orva-home/patients-et-familles/glossaire/stridor"
              target="_blank"
            >
              https://www.chuv.ch/fr/voies-aeriennes/orva-home/patients-et-familles/glossaire/stridor
            </a>{" "}
          </p>
        </div>
      </section>
      {/* <section>Article disponible prochainement.</section> */}
    </article>
  );
}
