// article is the main container
// must put section inside the article to add the margin bottom between sections
// div in the section will add margin to the left
// div.half_to_right will put the div to right in half size 50% to the parent
// ul and li still need a droplet for it but it work fines for now
// p will be just for breaking the line or paragraph
// b will be for bold

import { ReactComponent as PDF } from "../../../assets/images/pdf.svg";
import mise_en_place_pdf from "../../../assets/pdf/mise_en_place.pdf";


export function Slide2_4(props) {
  return (
    <article>
      <section>
        <p>
          L'Organisation mondiale de la santé (OMS) définit le Patient Blood
          Management (PBM) comme « une approche systématique axée sur le
          patient, fondée sur des données probantes et visant à optimiser la
          gestion des patients et la transfusion de produits sanguins pour
          offrir aux patients des soins efficaces et de qualité ». Tout ceci dans
          un objectif d’améliorer les résultats cliniques grâce à l'utilisation
          sûre et rationnelle du sang et des produits sanguins en minimisant
          l'exposition inutile à ces derniers<sup>1</sup>.
        </p>
      </section>
      <section>
        <p>
          Depuis que l’OMS a défini précisément ce qu’est le PBM et les
          objectifs qu’il vise à atteindre, des programmes de gestion
          personnalisée du capital sanguin ont vu le jour partout dans le monde
          <sup>2</sup>. En 2020, la Haute Autorité de Santé (HAS) a inscrit des
          recommandations de gestion personnalisée du sang à son programme de
          travail, sous la forme d’une note de cadrage. Ces dernières ont pour
          but d’améliorer la prise en charge des patients à chaque stade de leur
          intervention chirurgicale : en pré-opératoire, per-opératoire et
          post-opératoire<sup>3,4</sup>.
        </p>
      </section>

      <section>
        <p>Les principes du PBM reposent sur 3 piliers:</p>
        <ul>
          <li>
            L’amélioration de l’identification et de la prise en charge des
            patients présentant une anémie pré-opératoire
          </li>
          <li>
            La minimisation des pertes sanguines par des techniques
            chirurgicales mini invasives et l’utilisation de traitements contre
            le saignement
          </li>
          <li>
            L’optimisation de la tolérance du patient à l’anémie per-opératoire
            et post-opératoire<sup>2</sup>.
          </li>
        </ul>
      </section>
      <section>
        <b>Les bénéfices médico-économiques du PBM</b>
        <p>
          Le PBM est bénéfique aux patients et aux structures sanitaires à
          chaque stade de la prise en charge<sup>2,3</sup>.
        </p>
      </section>
      <section>
        <p>
          La prévalence de l’anémie chez les patients porteurs de pathologies
          qui relèvent d’une opération chirurgicale est de 20 à 40% chez les
          diabétiques sans complications rénales, de 30 à 50% chez les patients
          souffrant d’insuffisance cardiaque chronique, de 30 à 60% chez les
          patients souffrant d’arthrose rhumatoïde, et de 40 à 60% chez les
          personnes souffrant d’insuffisance rénale chronique<sup>4</sup>.
        </p>
      </section>
      <section>
        <p>
          En tenant compte du pourcentage élevé (41%) de risques de mortalité
          chez les patients souffrant d’anémie pré-opératoire, la prise en
          charge de cette pathologie constitue une nécessité absolue. Car si la
          détection de l’anémie pré-opératoire ne diminue pas les risques
          d’hémorragie pour les opérations à risque, sa prise en charge et donc
          son traitement garantit néanmoins une augmentation de l’hémoglobine,
          ce qui réduit les risques de morbi-mortalité liés à une transfusion
          sanguine lors de l’intervention<sup>4</sup>.
        </p>
      </section>

      <section>
        <p>
          Lors de l’application des programmes de PBM, les bénéfices suivants
          ont été observés<sup>5</sup> :
        </p>
        <ul>
          <li>Une diminution du nombre de transfusion</li>
          <li>Une baisse de la durée moyenne des séjours à l’hôpital</li>
          <li>Une diminution des complications</li>
          <li>Une diminution de la mortalité.</li>
        </ul>
      </section>

      <section>
        <p>
          D’un point de vue médical, la gestion personnalisée du capital sanguin
          est une approche qui permet d’éviter le recours à la transfusion et
          les complications qui y sont associées. Les risques de mortalité sont,
          par conséquent, nettement diminués.
          <br /> Des analyses des bénéfices économiques du PBM<sup>5,6</sup> ont
          été réalisées afin d’évaluer les avantages de ces programmes sur les
          coûts liés à la transfusion sanguine. Sur le plan économique, la
          diminution des coûts de la transfusion et de la durée des séjours à
          l’hôpital permet de limiter les frais en produit sanguin d’au moins
          20%<sup>6</sup>.
        </p>
      </section>

      <br />
      <figure className="half_to_right">
        <div style={{ width: "100px" }}>
          <a href={mise_en_place_pdf} target="_blank" rel="">
            <PDF />
          </a>
        </div>
        <figcaption className="caption">
          {" "}
          Infographie - Mise en place du PBM
        </figcaption>
      </figure>

      <br />
      <section>
        <div style={{ marginLeft: "0rem" }}>
          <p>
            <sup>1</sup>Meybohm.P Health economics of Patient Blood Management:
            a cost‐benefit analysis based on a meta‐analysis, Vox Sanguinis,
            décembre 2019 ,DOI: 10.1111/vox.12873
          </p>
          <p>
            <sup>2</sup>World Health Organization. The urgent need to implement
            patient blood management. 2021. World Health Organization,{" "}
            <a
              href="https://apps.who.int/iris/handle/10665/346655"
              target="_blank"
            >
              https://apps.who.int/iris/handle/10665/346655
            </a>{" "}
          </p>
          <p>
            <sup>3</sup>Améliorer la gestion du sang des patients pour une
            meilleures qualité de prise en charge lors de chirurgies majeures,
            sur le site de la SFCO [En ligne],{" "}
            <a
              href="https://sfco.fr/2021/01/26/ameliorer-la-gestion-du-sang-des-patients-pour-une-meilleure-qualite-de-prise-en-charge-lors-de-chirurgies-majeures/"
              target="_blank"
            >
              https://sfco.fr/2021/01/26/ameliorer-la-gestion-du-sang-des-patients-pour-une-meilleure-qualite-de-prise-en-charge-lors-de-chirurgies-majeures/
            </a>{" "}
            (consulté le 27 décembre 2021)
          </p>
          <p>
            <sup>4</sup>(2014), Anemia in the pré-surgical patient, sur le site
            de Society for the Advancement of Blood Management [En ligne],{" "}
            <a href="#" target="_blank">
              ANEMIA IN THE PRE-SURGICAL PATIENT
            </a>{" "}
            (consulté le 27 décembre 2021)
          </p>
          <p>
            <sup>5</sup>(2019), Gestion personnalisée du capital sanguin 15e
            législature, sur le site du senat.fr, [en ligne]{" "}
            <a
              href="https://www.senat.fr/questions/base/2019/qSEQ19020664S.html"
              target="_blank"
            >
              https://www.senat.fr/questions/base/2019/qSEQ19020664S.html
            </a>{" "}
            , (page consultée le 27 décembre 2021)
          </p>
          <p>
            <sup>6</sup>ResearchGate - Health economics of Patient Blood
            Management: a cost‐benefit analysis based on a meta‐analysis - [En
            ligne]{" "}
            <a
              href="https://www.researchgate.net/publication/337892761"
              target="_blank"
            >
              https://www.researchgate.net/publication/337892761
            </a>{" "}
            _Health_economics_of_Patient_Blood_Manageme nt_a_cost-benefit_
            analysis_based_on_a_meta-analysis, (consulté le 27 décembre 2021)
          </p>
        </div>
      </section>
      {/* <section>Article disponible prochainement.</section> */}
    </article>
  );
}
