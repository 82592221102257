import { useEffect, useState } from "react";
import { useLocation, Link, NavLink } from "react-router-dom";

export default function MenuItem(props) {
  const [hover, sethover] = useState(false);

  // const ChangeColor = () => {
  //   let CurrentPath = props.CurrentPath;
  //   if (CurrentPath === "/acceuil") {
  //     return "white";
  //   } else {
  //     return "yellow";
  //   }
  // };

  const handleHover = () => {
    // setState( hover: !state.hover });
    sethover(!hover);
  };

  const styles = {
    container: {
      opacity: 1,
      animation: "1s appear forwards",
      animationDelay: props.delay,
    },
    menuItem: {
      fontFamily: `'Open Sans', sans-serif`,
      fontSize: "1rem",
      padding: "1rem 0",
      margin: "0%",
      cursor: "pointer",
      // color: ChangeColor,
      transition: "color 0.5s 2s ease-in-out",
      animation: "2s slideIn forwards",
      animationDelay: props.delay,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    line: {
      width: "90%",
      height: "1px",
      background: "gray",
      margin: "0 auto",
      animation: "0.5s 0.5s shrink forwards",
      animationDelay: props.delay,
    },
  };
  return (
    <div style={styles.container}>
      <div
        style={styles.menuItem}
        onMouseEnter={() => {
          handleHover();
        }}
        onMouseLeave={() => {
          handleHover();
        }}
        onClick={props.onClick}
      >
        <div className="menuItem_number">{props.children.number}</div>
        <Link className="menuItem" to={props.children.path}>
          {props.children.text}
        </Link>
      </div>
      {/* <div style={styles.line} /> */}
    </div>
  );
}
