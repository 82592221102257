import React from "react";
import Header from "./Header";

export default function Main() {
  const styles = {
    main: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "0vh",
    },
  };
  return (
    <div style={styles.main}>
      <Header />
    </div>
  );
}
