import { useCallback, useEffect, useRef, useState } from "react";
import { animated, useTransition, useSpringRef } from "react-spring";
import { useMediaQuery } from "react-responsive";

import Lecture from "./Lecture";
import { useSearchParams } from "react-router-dom";
import classNames from "classnames";

export function Slider({ slides = [], displayed = false }) {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1080 });
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1251 });

  let [searchParams, setSearchParams] = useSearchParams();
  const [[index, dir], setIndex] = useState([0, 0]);
  const transRef = useSpringRef();
  const scrollBar = useRef();
  const transitions = useTransition(index, {
    ref: transRef,
    keys: index,
    from: {
      transform: `translate3d(${dir === 1 ? 100 : -100}%,0,0)`,
      opacity: 0,
    },
    enter: {
      transform: `translate3d(${dir === 1 ? 0 : 0}%,0,0)`,
      opacity: 1,
    },
    leave: {
      transform: `translate3d(${dir === 1 ? -100 : 100}%,0,0)`,
      opacity: 0,
    },
    config: { duration: 500 },
  });
  useEffect(() => {
    scrollBar.current.scrollTo(0, 0);
    transRef.start();
    displayed && setSearchParams({ page: index }, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  useEffect(() => {
    const page = searchParams.get("page");
    if (Number.isInteger(parseInt(page))) {
      setIndex((state) => [parseInt(page) % slides.length, 0]);
    }
  }, []);

  const handleNextSlide = useCallback(
    (dir) =>
      setIndex((state) => [
        (state[0] + dir + slides.length) % slides.length,
        dir,
      ]),

    []
  );
  // const isTabletOrMobile = true;
  const haveRight = index < slides.length - 1;
  const haveLeft = index !== 0;
  const haveLeftAndRight = haveRight && haveLeft;

  return (
    <>
      <div
        className="slider-container-outer"
        style={{ paddingTop: isTabletOrMobile && "5rem" }}
      >
        <animated.div
          className={classNames("lecture", isDesktopOrLaptop && "lecture_free")}
        >
          <Lecture
            isTabletOrMobile={isTabletOrMobile}
            position={(360 / slides.length) * index}
          />
          <p>{slides[index].time}</p>
        </animated.div>
        <div className="article-information">
          <h1 className="article-title">{slides[index].title}</h1>
          <h3
            className="article-subtitle"
            dangerouslySetInnerHTML={{ __html: slides[index].subTitle }}
          ></h3>
        </div>
        <div ref={scrollBar} className="slider-container scrollbar">
          {transitions((styles, i) => {
            const Slide = slides[i].slide;
            return (
              <animated.div
                style={styles}
                className="slider-item"
                data-position="1"
              >
                <Slide />
              </animated.div>
            );
          })}
        </div>
        <div
          className={`pagination ${
            haveRight && !isTabletOrMobile ? "haveRight" : ""
          } ${haveLeft && !isTabletOrMobile ? "haveLeft" : ""} ${
            haveLeftAndRight && isTabletOrMobile ? "haveLeft haveRight" : ""
          } ${isTabletOrMobile ? "mobile" : ""}`}
        >
          <div className="left">
            {haveLeft && (
              <div
                className="btn-slider"
                onClick={() => handleNextSlide(-1)}
              ></div>
            )}
          </div>
          <div className="middle">
            <div className="indictor">
              <div className="indicator-container">
                <div className={`dot major`}></div>
                {slides.map((data, i) => {
                  return (
                    <div
                      key={i}
                      className={`dot ${data["size"]} ${
                        i === index ? "selected" : ""
                      }`}
                    ></div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="right">
            {haveRight && (
              <div className="btn-slider" onClick={() => handleNextSlide(1)}>
                {transitions((styles, i) => {
                  const text = slides[i].nextButton;
                  return (
                    <animated.p
                      style={styles}
                      className="btn-slider-text"
                      data-position="1"
                    >
                      {text}
                    </animated.p>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
