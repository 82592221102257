import React from "react";

export default function Ml() {
  return (
    <div className="mention">
      <div className="mention-content">
      <svg viewBox="0 0 883 1769" xmlns="http://www.w3.org/2000/svg">
	<text id="Introduction" fill="#0a121a" fontFamily="GillSansNova-Bold, Gill Sans Nova" fontSize="24" transform="translate(1 135)">
		<tspan x="0" y="0">Introduction</tspan>
	</text>
	<text id="Politique_de_confidentialité" fill="#0a121a" fontFamily="GillSansNova-Bold, Gill Sans Nova" fontSize="24" transform="translate(1 427)" data-name="Politique de confidentialité">
		<tspan x="0" y="0">Politique de confidentialité</tspan>
	</text>
	<text id="Quel_type_s_de_données_collectons_nous_Sur_quel_s_fondement_s_collectons_nous_ces_données_" fill="#0a121a" fontFamily="GillSansNova-Bold, Gill Sans Nova" fontSize="24" transform="translate(1 575)" data-name="Quel type(s) de données collectons nous ?&#xA;Sur quel(s) fondement(s) collectons nous ces données ?">
		<tspan x="0" y="0">Quel type(s) de données collectons nous ?</tspan>
		<tspan x="0" y="28.8">Sur quel(s) fondement(s) collectons nous ces données ?</tspan>
	</text>
	<text id="Liens_hypertextes_et_cookies" fill="#0a121a" fontFamily="GillSansNova-Bold, Gill Sans Nova" fontSize="24" transform="translate(1 838)" data-name="Liens hypertextes et cookies">
		<tspan x="0" y="0">Liens hypertextes et cookies</tspan>
	</text>
	<text id="Loi_applicable_Litige_Indépendances_des_clauses" fill="#0a121a" fontFamily="GillSansNova-Bold, Gill Sans Nova" fontSize="24" transform="translate(1 1504)" data-name="Loi applicable – Litige – Indépendances des clauses">
		<tspan x="0" y="0">Loi applicable – Litige – Indépendances des clauses</tspan>
	</text>
	<text id="Dernière_mise_à_jour:_04_01_2023" fill="#0a121a" fontFamily="GillSansNova-Book, Gill Sans Nova" fontSize="18" transform="translate(1 1764)" data-name="Dernière mise à jour: 04/01/2023">
		<tspan x="0" y="0">Dernière mise à jour : 04/01/2023</tspan>
	</text>
	<text id="Mentions_légales" fill="#0a121a" fontFamily="GillSansNova-Bold, Gill Sans Nova" fontSize="48" transform="translate(1 54)" data-name="Mentions légales">
		<tspan x="0" y="0">Mentions légales</tspan>
	</text>
	<text id="L_utilisation_du_site_http:_nordicbloodtrail.fr_implique_l_acceptation_pleine_et_entière_des_conditions_générales_d_utilisation_ci-après_décrites._Nordic_Pharma_peut_modifier_la_présente_politique_de_confidentialité_à_tout_moment._La_date_de_la_Dernièr" fill="#03080d" fontFamily="GillSansNova-Book, Gill Sans Nova" fontSize="18" transform="translate(1 162)" data-name="L’utilisation du site http://nordicbloodtrail.fr/ implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites.&#xA;Nordic Pharma peut modifier la présente politique de confidentialité à tout moment. La date de la « Dernièr">
		<tspan x="0" y="20">L’utilisation du site http://nordicbloodtrail.fr/ implique l’acceptation pleine et entière des conditions générales d’utilisation</tspan>
		<tspan x="0" y="48.8">ci-après décrites.</tspan>
		<tspan x="0" y="77.6">Nordic Pharma peut modifier la présente politique de confidentialité à tout moment. La date de la « Dernière mise à jour »</tspan>
		<tspan x="0" y="106.4">des présentes Politiques de confidentialité indique quand a eu lieu la dernière modification de celles-ci.</tspan>
		<tspan x="0" y="135.2">Toute modification de la présente Politique de confidentialité prendra effet à la date de la « Dernière mise à jour »</tspan>
		<tspan x="0" y="164">indiquée en haut de la page. En utilisant le site ou en fournissant des informations à la suite desdites modifications, les</tspan>
		<tspan x="0" y="192.8">utilisateurs acceptent la nouvelle version de la Politique de confidentialité.</tspan>
	</text>
	<text id="Vous_pouvez_visiter_ce_site_sans_communiquer_votre_identité_ou_quelque_information_que_ce_soit_vous_concernant._Aucune_information_personnelle_ne_sera_collectée." fill="#03080d" fontFamily="GillSansNova-Book, Gill Sans Nova" fontSize="18" transform="translate(1 454)" data-name="Vous pouvez visiter ce site sans communiquer votre identité ou quelque information que ce soit vous concernant. Aucune information personnelle ne sera collectée.">
		<tspan x="0" y="20">Vous pouvez visiter ce site sans communiquer votre identité ou quelque information que ce soit vous concernant.</tspan>
		<tspan x="0" y="48.8">Aucune information personnelle ne sera collectée.</tspan>
	</text>
	<text id="En_France_les_données_personnelles_sont_notamment_protégées_par_la_loi_n_78-87_du_6_janvier_1978_la_loi_n_2004-801_du_6_août_2004_l_article_L._226-13_du_Code_pénal_et_la_Directive_Européenne_du_24_octobre_1995._À_l_occasion_de_l_utilisation_du_site_p" fill="#03080d" fontFamily="GillSansNova-Book, Gill Sans Nova" fontSize="18" transform="translate(1 631)" data-name="En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l’article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.&#xA;À l’occasion de l’utilisation du site, p">
		<tspan x="0" y="20">En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801</tspan>
		<tspan x="0" y="48.8">du 6 août 2004, l’article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.</tspan>
		<tspan x="0" y="77.6">À l’occasion de l’utilisation du site, peuvent être recueillies : l’URL des liens par l’intermédiaire desquels l’utilisateur a</tspan>
		<tspan x="0" y="106.4">accédé au site, le fournisseur d’accès de l’utilisateur, l’adresse de protocole Internet (IP) de l’utilisateur.</tspan>
		<tspan x="0" y="135.2">En tout état de cause la société NORDIC PHARMA ne collecte pas d’informations personnelles relatives à l’utilisateur.</tspan>
	</text>
	<text id="L_utilisateur_est_informé_que_lors_de_ses_visites_sur_le_sitehttp:_nordicbloodtrail.fr_un_cookie_peut_s_installer_automatiquement_sur_son_logiciel_de_navigation._Un_cookie_est_un_bloc_de_données_qui_ne_permet_pas_d_identifier_les_utilisateurs_mais_qui_" fill="#03080d" fontFamily="GillSansNova-Book, Gill Sans Nova" fontSize="18" transform="translate(1 865)" data-name="L’utilisateur est informé que, lors de ses visites sur le sitehttp://nordicbloodtrail.fr/, un cookie peut s’installer automatiquement sur son logiciel de navigation. Un cookie est un bloc de données qui ne permet pas d’identifier les utilisateurs mais qui ">
		<tspan x="0" y="20">L’utilisateur est informé que, lors de ses visites sur le site http://nordicbloodtrail.fr/, un cookie peut s’installer</tspan>
		<tspan x="0" y="48.8">automatiquement sur son logiciel de navigation. Un cookie est un bloc de données qui ne permet pas d’identifier les</tspan>
		<tspan x="0" y="77.6">utilisateurs mais qui a pour finalité d’enregistrer des informations relatives à la navigation de ce dernier sur le site.</tspan>
		<tspan x="0" y="106.4">NORDIC PHARMA utilise des cookies analytiques pour collecter des informations sur votre utilisation du Site Web dans</tspan>
		<tspan x="0" y="135.2">le but d’améliorer le contenu de celui-ci, de l’adapter davantage à vos souhaits et d’en accroître la facilité d’utilisation.</tspan>
		<tspan x="0" y="164">Le stockage de cookies dans votre terminal et l’accès aux données de navigation contenues dans ces fichiers ou</tspan>
		<tspan x="0" y="192.8">rattachées à ces fichiers, est subordonné à votre accord ou à votre défaut d’opposition.</tspan>
		<tspan x="0" y="221.6">Si vous avez accepté l’enregistrement d’un cookie sur votre terminal, vous pouvez manifester votre refus, à tout moment</tspan>
		<tspan x="0" y="250.4">en paramétrant votre logiciel de navigation.</tspan>
		<tspan x="0" y="279.2"/>
		<tspan x="0" y="308">Pour empêcher ou limiter le stockage des cookies sur votre terminal, vous pouvez modifier les paramètres du navigateur</tspan>
		<tspan x="0" y="336.8">que vous utilisez :</tspan>
		<tspan x="0" y="365.6"/>
		<tspan fontFamily="GillSansNova-Bold, Gill Sans Nova">
			<tspan x="0" y="394.4">Firefox : </tspan>
			<tspan fontFamily="GillSansNova-Book, Gill Sans Nova" y="394.4" xmlSpace="preserve"><a target={"_blank"} rel="noreferrer"   href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a></tspan>
			<tspan x="0" y="423.2">IE : </tspan>
			<tspan fontFamily="GillSansNova-Book, Gill Sans Nova" y="423.2" xmlSpace="preserve"><a target={"_blank"} rel="noreferrer"  href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies</a></tspan>
			<tspan x="0" y="452">Chrome : </tspan>
			<tspan fontFamily="GillSansNova-Book, Gill Sans Nova" y="452" xmlSpace="preserve"><a target={"_blank"} rel="noreferrer"  href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=en">https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=en</a></tspan>
			<tspan x="0" y="480.8">Safari : </tspan>
			<tspan fontFamily="GillSansNova-Book, Gill Sans Nova" y="480.8" xmlSpace="preserve"><a target={"_blank"} rel="noreferrer"  href="https://support.apple.com/kb/PH21411?locale=fr_FR&amp;viewlocale=en_US">https://support.apple.com/kb/PH21411?locale=fr_FR&amp;viewlocale=en_US</a></tspan>
			<tspan x="0" y="509.6">Opera : </tspan>
			<tspan fontFamily="GillSansNova-Book, Gill Sans Nova" y="509.6" xmlSpace="preserve"><a target={"_blank"} rel="noreferrer"  href="http://www.opera.com/help/tutorials/security/cookies/">http://www.opera.com/help/tutorials/security/cookies/</a></tspan>
		</tspan>
		<tspan x="0" y="538.4"/>
		<tspan x="0" y="567.2">Si votre navigateur ne figure pas dans la liste, vous pouvez vous rendre directement sur le site de son éditeur.</tspan>
	</text>
	<text id="Toute_difficulté_réclamation_ou_litige_provenant_ou_lié_e_aux_présentes_Mentions_légales_sera_régi_e_selon_la_loi_Française._En_cas_de_contestation_sur_l_interprétation_ou_l_exécution_des_présentes_Conditions_et_à_défaut_d_accord_amiable_les_tribunaux" fill="#03080d" fontFamily="GillSansNova-Book, Gill Sans Nova" fontSize="18" transform="translate(1 1531)" data-name="Toute difficulté, réclamation ou litige provenant ou lié(e) aux présentes Mentions légales sera régi(e) selon la loi Française. En cas de contestation sur l’interprétation ou l’exécution des présentes Conditions, et à défaut d’accord amiable, les tribunaux">
		<tspan x="0" y="20">Toute difficulté, réclamation ou litige provenant ou lié(e) aux présentes Mentions légales sera régi(e) selon la loi Française.</tspan>
		<tspan x="0" y="48.8">En cas de contestation sur l’interprétation ou l’exécution des présentes Conditions, et à défaut d’accord amiable, les</tspan>
		<tspan x="0" y="77.6">tribunaux de Paris seront seuls compétents pour connaître du litige. Si n’importe quelle disposition de ces Mentions</tspan>
		<tspan x="0" y="106.4">légales est tenue pour être illégale, invalide ou inapplicable, en application d’une loi, d’un règlement ou à la suite d’une</tspan>
		<tspan x="0" y="135.2">décision définitive d’une juridiction compétente, les dispositions restantes resteront en vigueur.</tspan>
	</text>
</svg>
      </div>
      
    </div>
  );
}
