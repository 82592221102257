// article is the main container
// must put section inside the article to add the margin bottom between sections
// div in the section will add margin to the left
// div.half_to_right will put the div to right in half size 50% to the parent
// ul and li still need a droplet for it but it work fines for now
// p will be just for breaking the line or paragraph
// b will be for bold
import { ReactComponent as PDF } from "../../../assets/images/pdf.svg";
export function Slide1_2(props) {
  return (
    <article>
      <section>
        <p>
          <b>Produits sanguins labiles : codes et tarification</b>
        </p>
        <p>
          Les produits sanguins labiles (PSL) sont destinés aux patients devant
          subir une transfusion sanguine. Ils correspondent aux produits obtenus
          à la suite d’un don de sang de bénévoles et doivent être conformes aux
          règles de sélection des donneurs, de conservation, de transport et
          d&#39;analyse afin d’assurer des interventions sécurisées aux patients
          qui doivent être transfusés<sup>1</sup>.
        </p>
      </section>
      <section>
        <p>
          <b>La commercialisation des produits sanguins labiles</b>
        </p>
        <p>
          En France, c’est l’Établissement Français du Sang (EFS), un organisme
          public administratif de l’État qui est responsable de la gestion du
          sang et de sa commercialisation. Il est par ailleurs responsable de
          l’autosuffisance en produits sanguins labiles afin d’assurer
          l’approvisionnement de la population française lorsque cela est
          nécessaire<sup>2</sup>.
        </p>
      </section>
      <section>
        <p>
          Les tarifs de cession des produits sanguins labiles sont fixés par
          arrêté ministériel<sup>3</sup>.
        </p>
      </section>
      <section>
        <p>
          <b>
            Codification des produits sanguins labiles à usage thérapeutique
          </b>
        </p>
        <p>
          La traçabilité des produits sanguins labiles est une procédure
          obligatoire essentielle du dispositif d’hémovigilance institué par le
          décret du 24 janvier 1994, pivot de la sécurité transfusionnelle. La
          traçabilité repose sur l’échange d’informations fiables entre
          établissements de santé et établissements de transfusion.<sup>4</sup>{" "}
          Dans ce sens, la décision DG n° 2005-135 du 27 juillet 2005 portant
          modification de la directive technique n<sup>o</sup> 2 bis de l’Agence française
          du sang du 24 novembre 1997 relative aux conditions de mise en place
          de l’informatisation de la traçabilité des produits sanguins labiles a
          permis d’établir la codification suivante<sup>5</sup>.
        </p>
      </section>
      <section>
        <table>
          <tr style={{ background: "var(--background-color)", color: "white" }}>
            <th style={{ width: "50%" }}>
              Produit sanguin labile<sup>5</sup>
            </th>
            <th>Code</th>
          </tr>
          <tr>
            <td>
              Concentré globules rouges autologue unité enfant (Transformation :
              cryoconservé clos)
            </td>
            <td>4715</td>
          </tr>
          <tr>
            <td>
              Concentré globules rouges autologue unité adulte (Transformation :
              déleucocyté cryoconservé)
            </td>
            <td>4775</td>
          </tr>
          <tr>
            <td>
              Concentré globules rouges autologue unité adulte (Transformation :
              déleucocyté cryoconservé clos)
            </td>
            <td>4776</td>
          </tr>
          <tr>
            <td>
              Concentré globules rouges autologue unité enfant (Transformation :
              déleucocyté cryoconservé)
            </td>
            <td>4779</td>
          </tr>
        </table>
        <br />
        {/* tablE2 */}
        <table>
          <tr>
            <td style={{ width: "50%" }}>
              Concentré globules rouges autologue unité enfant (Transformation :
              déleucocyté cryoconservé clos)
            </td>
            <td>4780</td>
          </tr>
          <tr>
            <td>
              Concentré globules rouges d’aphérèse homologue (1re unité CGRAUA)
              préparation pédiatrique (1re unité) (Transformation : déleucocyté
              clos)
            </td>
            <td>4883</td>
          </tr>
          <tr>
            <td>
              Concentré globules rouges d’aphérèse homologue (1re unité CGRAUA)
              préparation pédiatrique (2e unité) (Transformation : déleucocyté
              clos)
            </td>
            <td>4884</td>
          </tr>
          <tr>
            <td>
              Concentré globules rouges d’aphérèse homologue (1re unité CGRAUA)
              préparation pédiatrique (3e unité) (Transformation : déleucocyté
              clos)
            </td>
            <td>4885</td>
          </tr>
          <tr>
            <td>
              Concentré globules rouges d’aphérèse homologue (1re unité CGRAUA)
              préparation pédiatrique (4e unité) (Transformation : déleucocyté
              clos)
            </td>
            <td>4886</td>
          </tr>
          <tr>
            <td>
              Concentré globules rouges d’aphérèse homologue (2e unité CGRAUA)
              préparation pédiatrique (1re unité) (Transformation : déleucocyté
              clos)
            </td>
            <td>4887</td>
          </tr>
          <tr>
            <td>
              Concentré globules rouges d’aphérèse homologue (2e unité CGRAUA)
              préparation pédiatrique (2e unité) (Transformation : déleucocyté
              clos)
            </td>
            <td>4888</td>
          </tr>
          <tr>
            <td>
              Concentré globules rouges d’aphérèse homologue (2e unité CGRAUA)
              préparation pédiatrique (3e unité) (Transformation : déleucocyté
              clos)
            </td>
            <td>4889</td>
          </tr>
          <tr>
            <td>
              Concentré globules rouges d’aphérèse homologue (2e unité CGRAUA)
              préparation pédiatrique (4e unité) (Transformation : déleucocyté
              clos)
            </td>
            <td>4890</td>
          </tr>
          <tr>
            <td>
              Concentré globules rouges d’aphérèse homologue (1re unité CGRAUA)
              préparation pédiatrique (1re unité) (Transformation : déleucocyté
              clos irradié)
            </td>
            <td>4891</td>
          </tr>
          <tr>
            <td>
              Concentré globules rouges d’aphérèse homologue (1re unité CGRAUA)
              préparation pédiatrique (2e unité) (Transformation : déleucocyté
              clos irradié)
            </td>
            <td>4892</td>
          </tr>
          <tr>
            <td>
              Concentré globules rouges d’aphérèse homologue (1re unité CGRAUA)
              préparation pédiatrique (3e unité) (Transformation : déleucocyté
              clos irradié)
            </td>
            <td>4893</td>
          </tr>
          <tr>
            <td>
              Concentré globules rouges d’aphérèse homologue (1re unité CGRAUA)
              préparation pédiatrique (4e unité) (Transformation : déleucocyté
              clos irradié)
            </td>
            <td>4894</td>
          </tr>
        </table>
        <br />
        {/* table3 */}
        <table>
          <tr>
            <td style={{ width: "50%" }}>
              Concentré globules rouges d’aphérèse homologue (2e unité CGRAUA)
              préparation pédiatrique (1re unité) (Transformation : déleucocyté
              clos irradié)
            </td>
            <td>4895</td>
          </tr>
          <tr>
            <td>
              Concentré globules rouges d’aphérèse homologue (2e unité CGRAUA)
              préparation pédiatrique (2e unité) (Transformation : déleucocyté
              clos irradié)
            </td>
            <td>4896</td>
          </tr>
          <tr>
            <td>
              Concentré globules rouges d’aphérèse homologue (2e unité CGRAUA)
              préparation pédiatrique (3e unité) (Transformation : déleucocyté
              clos irradié)
            </td>
            <td>4897</td>
          </tr>
          <tr>
            <td>
              Concentré globules rouges d’aphérèse homologue (2e unité CGRAUA)
              préparation pédiatrique (4e unité) (Transformation : déleucocyté
              clos irradié)
            </td>
            <td>4898</td>
          </tr>
          <tr>
            <td>
              Plasma frais congelé sécurisé issu de sang total (Transformation :
              déleucocyté)
            </td>
            <td>20020</td>
          </tr>
          <tr>
            <td>
              Plasma frais congelé viro attenue-BM issu d’aphérèse (1re unité)
              (Transformation : déleucocyté)
            </td>
            <td>20202</td>
          </tr>
          <tr>
            <td>
              Plasma frais congelé viro attenue-BM issu d’aphérèse (2e unité)
              (Transformation : déleucocyté)
            </td>
            <td>20203</td>
          </tr>
          <tr>
            <td>
              Plasma frais congelé viro attenue-BM issu d’aphérèse (3e unité)
              (Transformation : déleucocyté)
            </td>
            <td>20204</td>
          </tr>
          <tr>
            <td>
              Plasma frais congelé viro attenue-BM issu de sang total
              (Transformation : déleucocyté)
            </td>
            <td>20205</td>
          </tr>
          <tr>
            <td>
              Plasma frais congelé viro attenue-BM issu d’aphérèse (1re unité)
              (Transformation : déleucocyté décongelé)
            </td>
            <td>20212</td>
          </tr>
          <tr>
            <td>
              Plasma frais congelé viro attenue-BM issu d’aphérèse (2e unité)
              (Transformation : déleucocyté décongelé)
            </td>
            <td>20213</td>
          </tr>
          <tr>
            <td>
              Plasma frais congelé viro attenue-BM issu d’aphérèse (3e unité)
              (Transformation : déleucocyté décongelé)
            </td>
            <td>20214</td>
          </tr>
          <tr>
            <td>
              Plasma frais congelé viro attenue-BM issu de sang total
              (Transformation : déleucocyté décongelé)
            </td>
            <td>20215</td>
          </tr>
          <tr>
            <td>
              Plasma congelé spécifique anti-vaccine (Transformation :
              déleucocyté)
            </td>
            <td>23423</td>
          </tr>
          <tr>
            <td>
              Mélange de plasmas décongelés spécifiques anti-vaccine
              (Transformation : déleucocyté)
            </td>
            <td>23424</td>
          </tr>
        </table>
      </section>
      <br />
      <section>
        <p>
          <b>
            Les tarifs des produits sanguins labiles<sup>6</sup>
          </b>
        </p>
        <p>
          L’arrêté ministériel du 9 mars 2010 relatif au tarif de cession des
          produits sanguins labiles régit la commercialisation de ces derniers.
          Dans les conditions de l’article 1 de l’arrêté du 9 mars 2010, il est
          précisé que le “tarif de cession des produits sanguins labiles et des
          plasmas inclut, en plus du produit lui-même, le récipient et son
          étiquette, les frais de prélèvement, qualification, stockage et
          distribution ainsi que le conseil transfusionnel, à l'exclusion des
          frais de livraison”. <br />
          <br />
          L’article 2 de l’arrêté ministériel du 9 mars 2010 a défini les tarifs
          de cession des produits sanguins labiles comme suit :
        </p>
      </section>
      <section>
        <p>En euros (HT)</p>
        <table>
          <tr>
            <td style={{ width: "50%" }}>
              Sang humain total (unité adulte, unité enfant et unité
              pédiatrique)
            </td>
            <td>121,67</td>
          </tr>
          <tr>
            <td>
              Concentré de globules rouges humains homologues (unité adulte,
              unité enfant et unité pédiatrique)
            </td>
            <td>201,23</td>
          </tr>
          <tr>
            <td>Concentré unitaire de granulocytes d'aphérèse</td>
            <td>2190</td>
          </tr>
          <tr>
            <td>Mélange de concentrés de granulocytes de sang total</td>
            <td>1888</td>
          </tr>
          <tr >
            <th style={{ width: "50%" }}>
              Mélange de concentrés de plaquettes standard :
            </th>
            <th></th>
          </tr>
          <tr>
            <td>- concentration minimale de 1 × 10 ¹ ¹ plaquettes par poche</td>
            <td>82,11</td>
          </tr>
          <tr>
            <td>
              - puis par tranche supplémentaire d'unité thérapeutique de 0,5 ×
              10 ¹ ¹
            </td>
            <td>41,06</td>
          </tr>
          <tr>
            <th>Concentré de plaquettes d'aphérèse :</th>
            <th></th>
          </tr>
          <tr>
            <td>- concentration minimale de 2 × 10 ¹ ¹ plaquettes par poche</td>
            <td>238,13</td>
          </tr>
          <tr>
            <td>
              - puis par tranche supplémentaire d'unité thérapeutique de 0,5 ×
              10 ¹ ¹
            </td>
            <td>59,52</td>
          </tr>
          <tr>
            <th style={{ width: "50%" }}>
              Mélange de concentrés de plaquettes standard viro atténué par
              amotosalen :
            </th>
            <th></th>
          </tr>
          <tr>
            <td>- concentration minimale de 1 × 10 ¹ ¹ plaquettes par poche</td>
            <td>82,11</td>
          </tr>
          <tr>
            <td>
              - puis par tranche supplémentaire d'unité thérapeutique de 0,5 ×
              10 ¹ ¹
            </td>
            <td>41,06</td>
          </tr>
          <tr>
            <th>
              Concentré de plaquettes d'aphérèse viro atténué par amotosalen :
            </th>
            <th></th>
          </tr>
          <tr>
            <td>- concentration minimale de 2 × 10 ¹ ¹ plaquettes par poche</td>
            <td>238,13</td>
          </tr>
          <tr>
            <td>
              - puis par tranche supplémentaire d'unité thérapeutique de 0,5 ×
              10 ¹ ¹
            </td>
            <td>59,52</td>
          </tr>
          <tr>
            <td>
              Forfait pour concentrés globules rouges autologues (unités adultes
              SAGM-M par érythraphérèse)
            </td>
            <td>471,54</td>
          </tr>
          <tr>
            <td>
              Forfait pour transfusion autologue programmée (comprenant un
              concentré de globules rouges et un plasma frais congelé
              autologues), par prélèvement
            </td>
            <td>243,89</td>
          </tr>
          <tr>
            <td>
              Majoration pour transformation "déleucocyté" (applicable sur
              concentré de globules rouges autologue)
            </td>
            <td>27,27</td>
          </tr>
          <tr>
            <td>Majoration pour transformation "cryoconservé"</td>
            <td>129,47</td>
          </tr>
          <tr>
            <td>Majoration pour qualification "phénotypé Rh Kell"</td>
            <td>3,53</td>
          </tr>
          <tr>
            <td>Majoration pour qualification "phénotype étendu"</td>
            <td>16,43</td>
          </tr>
          <tr>
            <td>Majoration pour qualification "CMV négatif"</td>
            <td>11,62</td>
          </tr>
          <tr>
            <td>Majoration pour transformation "déplasmatisé"</td>
            <td>78,59</td>
          </tr>
          <tr>
            <td>
              Majoration pour transformation "irradié" (applicable sur chaque
              produit)
            </td>
            <td>15,90</td>
          </tr>
          <tr>
            <td>Majoration pour transformation "réduction de volume"</td>
            <td>24,96</td>
          </tr>
          <tr>
            <td>
              Majoration pour transformation "reconstitution du sang à usage
              pédiatrique"
            </td>
            <td>26,32</td>
          </tr>
          <tr>
            <td>
              Majoration pour transformation "CGR cryoconservé suspendu en
              solution SAG-M après décongélation"
            </td>
            <td>182,40</td>
          </tr>
        </table>
      </section>
      <section>
        <div style={{ marginLeft: "0rem" }}>
          <p>
            <sup>1</sup>Les produits sanguins labiles, sur le site de l’EFS [En
            ligne], {" "}
            <a
              href="https://www.efs.sante.fr/activite/les produits-sanguins-labiles"
              target="_blank"
            >
              https://www.efs.sante.fr/activite/les produits-sanguins-labiles
            </a>{" "}
            - (Consulté le 27 décembre 2021)
          </p>
          <p>
            <sup>2</sup>Établissement Français du Sang - Nos missions [En
            ligne],{" "}
            <a
              href="https://www.efs.sante.fr/lefs/nos missions"
              target="_blank"
            >
              https://www.efs.sante.fr/lefs/nos missions
            </a>{" "}
            (page consultée le 27 décembre 2021)
          </p>
          <p>
            <sup>3</sup>Ministère des solidarités et de la santé. 2021. “Arrêté
            du 16 décembre 2021 modifiant l'arrêté du 9 mars 2010 relatif au
            tarif de cession des produits sanguins labiles.” Légifrance, {" "}
            <a
              href="https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000044525571"
              target="_blank"
            >
              https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000044525571
            </a>{" "}
          </p>
          <p>
            <sup>4</sup>Résumé de Circulaire DGS/DH/AFS/97 N° 97/816 du 24
            Decembre 1997 relative à l'informatisation de la traçabilité des
            produits sanguins labiles, {" "}
            <a
              href="http://hemovigilance-cncrh.fr/www2/Textes/1997/CIRDT2%20B_24_12_1997.html"
              target="_blank"
            >
              http://hemovigilance-cncrh.fr/www2/Textes/1997/CIRDT2%20B_24_12_1997.html
            </a>{" "}
          </p>
          <p>
            <sup>5</sup>Décision DG no 2005-135 du 27 juillet 2005, sur le site
            Solidarite-sante-gouv.fr [En ligne], {" "}
            <a
              href="https://solidarites-sante.gouv.fr/fichiers/bo/2005/05-09/a0090009.htm"
              target="_blank"
            >
              https://solidarites-sante.gouv.fr/fichiers/bo/2005/05-09/a0090009.htm
            </a>{" "}
            (Consulté le 27 décembre 2021)
          </p>
          <p>
            <sup>6</sup>(2021), Arrêté du 9 mars 2010 relatif au tarif de
            cession des produits sanguins labiles, sur le site legifrance.fr [En
            ligne], {" "}
            <a
              href="https://www.legifrance.gouv.fr/loda/id/LEGIARTI000039664256/2020-01-01/"
              target="_blank"
            >
              https://www.legifrance.gouv.fr/loda/id/LEGIARTI000039664256/2020-01-01/
            </a>{" "}
            (page consultée le 27 décembre 2021)
          </p>
        </div>
      </section>
    </article>
  );
}
