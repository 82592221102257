import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Menu from "./Menu";
import MenuButton from "./MenuButton";
import MenuItem from "./MenuItem";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { useMediaQuery } from "react-responsive";

function Header() {
  const [IsMenuopen, setIsMenuopen] = useState(false);
  const location = useLocation();
  const CurrentPath = location.pathname;
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1250 });

  // console.log(CurrentPath);
  const handleMenuClick = () => {
    setIsMenuopen((open) => !open);
  };

  const setfilledColor = () => {
    return <Logo id="logoPrincipale" />;
  };

  const handleLinkClick = () => {
    let ele = document.getElementsByClassName("circuit__scroll");
    ele[0]?.click();
    setIsMenuopen(false);
  };
  const styles = {
    container: {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: "99",
      opacity: 0.9,
      display: "flex",
      alignItems: "center",
      background: "transparent",
      width: "100%",
      color: "white",
      fontFamily: "Lobster",
    },
    logo: {
      // marginLeft: "2rem",
      // width: "25%",
      display: "flex",
      // background: "black",
      fill: isTabletOrMobile && IsMenuopen ? "white" : "",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100vw",
      height: "100vh",
      filter: IsMenuopen ? "blur(2px)" : null,
      transition: "filter 0.5s ease",
    },
  };

  // const menu = ["About Us", "Our Products", "Services", "FAQ", "Contact Us"];
  const menu = [
    { text: "Accueil", number: "01", path: "/acceuil" },
    { text: "Circuit du Don du Sang", number: "02", path: "/circuit" },
    { text: "Patient Blood Management", number: "03", path: "/patient" },
    { text: "Calculatrice TRUST", number: "04", path: "/simulator" },
    { text: "Contact", number: "05", path: "/contact" },
  ];

  // useEffect(() => {
  //   console.log(
  //     "🚀 ~ file: MenuItem.jsx ~ line 7 ~ MenuItem ~ CurrentPath",
  //     CurrentPath
  //   );
  // }, []);

  const menuItems = menu.map((val, index) => {
    return (
      <MenuItem
        key={index}
        delay={`${index * 2}s`}
        onClick={() => {
          handleLinkClick();
        }}
        CurrentPath={CurrentPath}
      >
        {val}
      </MenuItem>
    );
  });
  return (
    <div className="header">
      <div className="menubuttonClass" style={styles.container}>
        <div className="logo" style={styles.logo}>
          <Link to={"/"}>
            <div> {setfilledColor()}</div>
          </Link>

          {/* <img src={logo} alt="logo" style={{ width: "75%" }} /> */}
        </div>
        <MenuButton
          open={IsMenuopen}
          onClick={() => handleMenuClick()}
          color="white"
        />
      </div>
      <div className="header_menuItems">
        <Menu open={IsMenuopen}>{menuItems}</Menu>
      </div>
    </div>
  );
}

export default Header;
