// article is the main container
// must put section inside the article to add the margin bottom between sections
// div in the section will add margin to the left
// div.half_to_right will put the div to right in half size 50% to the parent
// ul and li still need a droplet for it but it work fines for now
// p will be just for breaking the line or paragraph
// b will be for bold
export function Slide1_3(props) {
  return (
    <article>
      <section>
        <p>
          Le PMSI ou Programme de Médicalisation des Systèmes d’Information est
          un dispositif propre au système de santé français qui a été introduit
          dans les années 1980. L’objectif principal de ce dernier était
          d’assurer une distribution équitable des ressources entre les
          établissements de santé. Ce n’est toutefois qu’en 1991 que le PMSI a
          été généralisé en France, permettant ainsi aux structures sanitaires
          françaises de mieux évaluer leur activité en visualisant les coûts des
          interventions, les frais d’hospitalisation qui y sont associés et la
          durée des séjours.
        </p>
      </section>
      <section>
        <p>
          <b>
            Qu'est-ce que les données PMSI<sup>2</sup>?
          </b>
        </p>
        <p>
          Les données PMSI correspondent aux informations médico-administratives
          concernant l’activité des établissements de santé et la gestion des
          ressources de ces derniers. Au sein des hôpitaux et des cliniques,
          c’est le département d’information médicale qui est responsable de la
          collecte et du traitement des données recueillies par le biais du
          PMSI. Chaque création de fichier et chaque traitement de données fait
          l’objet d’un avis préalable de la Commission Nationale de
          l’Informatique et des Libertés (CNIL).
        </p>
        <p>
          Le PMSI a permis de générer la première base de données permanente et
          médicalisée au niveau national. Au delà de son rôle d’outil
          d’allocation budgétaire il vise à d’autres utilisations :
        </p>
        <br />
        <ul>
          <li>
            Il permet d’évaluer le nombre d’hospitalisations et le type de
            pathologies traitées.
          </li>
          <li>Il facilite la mise en œuvre de nouvelles mesures.</li>
          <li>
            Il permet d’analyser le coût réel des hospitalisations au sein des
            établissements de santé français.
          </li>
        </ul>
        <br></br>
        <p>
          Afin d’évaluer l’efficacité budgétaire des établissements de santé, la
          prise en charge de chaque patient doit être dûment déclarée. Un tarif
          national validé par le ministère des affaires sociales est appliqué à
          chaque acte médical déclaré. L’addition de l’ensemble de ces tarifs
          permet à l’Agence régionale de l’hospitalisation (ARH) d’allouer des
          budgets aux hôpitaux.
        </p>
      </section>
      <section>
        <p>
          <b>
            PMSI et PBM<sup>3</sup>
          </b>
        </p>
        <p>
          Certains centres français ont développé un modèle d’impact budgétaire
          pour comparer deux situations : la “pratique clinique actuelle sans
          PBM” et la “pratique clinique avec la mise en œuvre du PBM” dans cinq
          domaines de chirurgie (orthopédique, cardiaque, urologique, vasculaire
          et digestive) selon la perspective de l&#39;hôpital et du payeur. Ce
          modèle utilise la base du PMSI pour estimer la population éligible, la
          proportion de patients transfusés, la durée moyenne de séjour, la
          proportion de patients anémiques et le coût de l&#39;hospitalisation :
        </p>
        <ul>
          <li>
            Plus de 2.2 millions de nouveaux patients pourraient bénéficier du
            PBM pour les cinq domaines chirurgicaux
          </li>
          <li>
            1.3 million de patients bénéficient actuellement du PBM pour les
            chirurgies orthopédiques avec des économies estimées à 427€ par
            patient
          </li>
          <li>
            La mise en œuvre du PBM permettrait une réduction de 17% de la durée
            moyenne de séjour et une réduction de 77% du nombre total de
            patients transfusés.
          </li>
        </ul>
      </section>
      <section>
        <p>
          <b>
            Les groupes homogènes de malades<sup>2</sup>
          </b>
        </p>
        <p>
          Les groupes homogènes de malades correspondent à des catégories de
          patients hospitalisés pour une courte durée.
        </p>
        <br />
        <p>
          Un compte rendu est produit à la sortie du patient, sur lequel figure
          le type d’hospitalisation et le ou les services fréquentés au cours du
          séjour à l’hôpital.
        </p>
        <br />
        <p>
          Afin d’automatiser le traitement des données recueillies, des codes
          sont utilisés :
        </p>
        <ul>
          <li>
            Le codage des diagnostics s’appuie sur la Classification
            Internationale des Maladies (CIM), organisé en 21 chapitres
          </li>
          <li>
            Le codage des actes médicaux s’appuie sur le Catalogue des actes
            médicaux (CDAM), structuré en 5 champs. Chaque champ est repéré par
            une lettre de l’alphabet grec :
          </li>
          <div>
            <ul>
              <li>Alpha pour les actes diagnostiques et thérapeutiques</li>
              <li>Bêta pour les actes d’anesthésiologie</li>
              <li>Gamma pour les actes d’imagerie médicale</li>
              <li>Mû pour les actes de radiothérapie</li>
              <li>Oméga pour les actes de réanimation</li>
            </ul>
          </div>
        </ul>
        <br></br>
        <p>
          Pour chaque acte médical, les informations suivantes sont précisées
          sur une ligne :
        </p>
        <ul>
          <li>
            le code de l&#39;acte : il se compose de quatre caractères, une
            lettre et trois chiffres
          </li>
          <li>le libellé de l&#39;acte,</li>
          <li>
            l&#39;Indice de Coût Relatif de l&#39;intervention qui est composé
            de trois chiffres
          </li>
          <li>
            la lettre &quot;Y&quot; qui indique le caractère qui classe
            l&#39;acte dans la classification des groupes homogènes de malades
          </li>
        </ul>
      </section>
      <br />
      <section>
        <p>
          {" "}
          <div style={{ marginLeft: "0rem" }}>
            <p>
              <sup>1</sup>Jean-Paul Domin, « Le Programme de médicalisation des
              systèmes d’information (PMSI) », Histoire, médecine et santé [En
              ligne], 4 | automne 2013, mis en ligne le 01 décembre 2014,
              consulté le 12 juillet 2022. URL :
              <a href="http://journals.openedition.org/hms/355" target="_blank">
                http://journals.openedition.org/hms/355
              </a>{" "}
              ; DOI:
              <a href="https://doi.org/10.4000/hms.355" target="_blank">
                https://doi.org/10.4000/hms.355
              </a>{" "}
              - (Consulté le 27 décembre 2021)
            </p>
            <p>
              <sup>2</sup>Faure.E, Le programme de médicalisation des systèmes
              d&#39;information (PMSI), sur le site du caducée.net [En ligne],{" "}
              <a
                href="https://www.caducee.net/DossierSpecialises/systeme-information-sante/pmsi.asp"
                target="_blank"
              >
                https://www.caducee.net/DossierSpecialises/systeme-information-sante/pmsi.asp
              </a>{" "}
              (page consultée le 27 décembre 2021)
            </p>
            <p>
              <sup>3</sup>L.Vasilescu, Budget impact analysis of the patient
              blood management from a french national payer and hospital
              perspective in France, 2018,{" "}
              <a
                href="https://www.valueinhealthjournal.com/article/S1098-3015(18)35924-2/fulltext"
                target="_blank"
              >
                https://www.valueinhealthjournal.com/article/S1098-3015(18)35924-2/fulltext
              </a>{" "}
            </p>
          </div>
        </p>
      </section>
    </article>
  );
}
