import React, { Component } from "react";
import Footer from "./Footer";

export default class Menu extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   open: this.props.open ? this.props.open : false,
    // };

    // console.log("🚀 ~ file: Menu.jsx:12 ~ Menu ~ constructor ~ props", props);
  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.open !== this.state.open) {
    //   // this.setState({ open: nextProps.open });
    // }
  }

  render() {
    const styles = {
      container: {
        position: "absolute",
        top: 0,
        right: 0,
        height: this.props.open ? "100%" : 0,
        width: "75vw",
        display: "flex",
        flexDirection: "column",
        background: "black",
        opacity: 0.95,
        color: "#fafafa",
        transition: "height 0.75s ease",
        zIndex: 2,
        justifyContent: "space-between",
      },
      menuList: {
        paddingTop: "3rem",
        display: "flex",
        flexDirection: "column",
        marginLeft: "10rem",
        alignItems: "flex-start",
      },
    };
    return (
      <div
        className="containerMenu"
        style={{ height: this.props.open ? "100%" : "0" }}
      >
        {this.props.open ? (
          <>
            <div className="containerMenu_block">{this.props.children}</div>
            <Footer className="Footer" name="Menu" />
          </>
        ) : null}
      </div>
    );
  }
}
