import React from "react";

export default function Contact() {
  return (
    <div className="contact">
      
      <section>
      <h1>Contact</h1>
        <p>
        Pour toute question complémentaire relative à cette politique ou à la manière dont Nordic Pharma traite vos données personnelles,
        <span> nous vous invitons à nous contacter</span>
        </p>
        <div className="contact-flex">
          <div>
          <svg height="167" width="232" viewBox="0 0 232 167" xmlns="http://www.w3.org/2000/svg">
	<text id="par_email_dataprivacy_nordicpharma.com" fill="#03080d" fontFamily="GillSansNova-Bold, Gill Sans Nova" fontSize="18" transform="translate(116 140)" data-name="par email&#xA;dataprivacy@nordicpharma.com">
		<tspan x="-49.581" y="0">PAR EMAIL</tspan>
		<tspan fontFamily="GillSansNova-Book, Gill Sans Nova">
			<tspan x="-115.587" y="21.6">dataprivacy@nordicpharma.com</tspan>
		</tspan>
	</text>
	<path id="mail_icon" d="M202.54,0a40,40,0,1,0,40,40,40,40,0,0,0-40-40M186.62,25.24h31.81a3.454,3.454,0,0,1,3.41,2.91c0,.19-18.56,12.36-18.56,12.36a1.357,1.357,0,0,1-1.53,0s-18.54-12.16-18.54-12.36a3.446,3.446,0,0,1,3.41-2.91m35.27,26.17a3.461,3.461,0,0,1-3.46,3.46H186.62a3.461,3.461,0,0,1-3.46-3.46V30.73l17.39,11.59a3.584,3.584,0,0,0,3.94,0l17.4-11.59Z" fill="#0a121a" transform="translate(-86.54)" data-name="mail icon"/>
</svg>
          </div>
          <div>
          <svg height="167" width="158" viewBox="0 0 158 167" xmlns="http://www.w3.org/2000/svg">
	<text id="par_téléphone_33_0_1_70_37_28_00." fill="#03080d" fontFamily="GillSansNova-Bold, Gill Sans Nova" fontSize="18" transform="translate(79 140)" data-name="par téléphone&#xA;+33 (0)1 70 37 28 00.">
		<tspan x="-77.193" y="0">PAR TÉLÉPHONE</tspan>
		<tspan fontFamily="GillSansNova-Book, Gill Sans Nova">
			<tspan x="-78.741" y="21.6">+33 (0)1 70 37 28 00.</tspan>
		</tspan>
	</text>
	<path id="phone_icon" d="M40,0A40,40,0,1,0,80,40,40,40,0,0,0,40,0M54.21,53.33a3.664,3.664,0,0,1-3.67,3.66,29.333,29.333,0,0,1-27.5-27.5,3.664,3.664,0,0,1,3.67-3.66h6.66a.992.992,0,0,1,.92.62l3.1,7.75a.99.99,0,0,1-.41,1.23l-3.04,1.83a.981.981,0,0,0-.37,1.32,20.13,20.13,0,0,0,7.88,7.89,1,1,0,0,0,1.33-.37l1.83-3.04a1,1,0,0,1,1.22-.42l7.75,3.1a1,1,0,0,1,.63.93Zm-10.04-22a.873.873,0,0,1,.87-.88,4.543,4.543,0,0,1,4.54,4.54.875.875,0,1,1-1.75,0,2.791,2.791,0,0,0-2.79-2.79.871.871,0,0,1-.87-.87m11.87,4.54a.873.873,0,0,1-.87-.88A10.134,10.134,0,0,0,45.04,24.87a.875.875,0,0,1,0-1.75A11.886,11.886,0,0,1,56.92,34.99a.875.875,0,0,1-.88.88" fill="#0a121a" transform="translate(39)" data-name="phone icon"/>
</svg>
          </div>
        </div>
        <p> Si un utilisateur estime que ses droits en matière de protection des données
          ont été violés par Nordic Pharma, il peut déposer une réclamation
          auprès de l’autorité chargée de contrôler la protection des données.
          L’autorité française chargée de contrôler la protection des données
          est la Commission nationale de l'informatique et des libertés (CNIL).{" "}
          </p>  


      </section>
    </div>
  );
}
