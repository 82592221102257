import React from "react";
// import { ReactComponent as FooterLogo } from "../../assets/images/logo_NP_white.svg";
import logo from "../../assets/images/logo_nordic_blanc.png";
export default function Footer(props) {
  const styles = {
    footer: {
      width: "75%",
      marginTop: "1rem",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      color: props.color,
      marginInline: "auto",
    },
    line: {
      height: "1px",
      width: "90%",
      background: props.color,
    },
    text: {
      padding: "0.5rem",
      color: "white",
    },
  };

  return (
    <div className="footerStyles">
      <div>
        {" "}
        {/* <FooterLogo className="FooterLogo" /> */}
        <img className="FooterLogo" alt="" src={logo}/>
      </div>
      <div className="rightblock">
        <h3> &#169; Nordic Pharma</h3>
        <a
          href="/mentionlegal"
          style={{
            textDecoration: "none",
            color: "var(--background-color)",
          }}
        >
          {" "}
          <h3>Mentions légales</h3>
        </a>
      </div>
    </div>
  );
}

// Footer.defaultProps = {
//   color: "white",
//   title: "hello world!",
// };

// Footer.propTypes = {
//   color: React.PropTypes.string,
//   title: React.PropTypes.string,
// };
