import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CircuitButton from "./buttons/CircuitButton";
import PatientButton from "./buttons/PatientButton";
import { useMediaQuery } from "react-responsive";

export default function Acceuil() {
  const [Isclicked, setclicked] = useState(false);
  const [WhoisClicked, setWhoisClicked] = useState("");
  const navigate = useNavigate();
  // React responsive queries
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1251 });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1250 });
  // const isBigScreen = useMediaQuery({ minWidth: 1824 });
  // const isPortrait = useMediaQuery({ orientation: "portrait" });
  // const isRetina = useMediaQuery({ minResolution: "2dppx" });
  const Section1 = useRef();
  const Section2 = useRef();
  useEffect(() => {
    // console.log(WhoisClicked);
    if (isDesktopOrLaptop) {
      if (WhoisClicked === "First") {
        console.log("i am here in first ");
        Section1.current.style.width = Isclicked ? "100%" : "50%";
        Section2.current.style.width = Isclicked ? "0%" : "50%";
        Section1.current.style.transition = Isclicked
          ? "width 0.5s ease"
          : "width 0.5s out";
        Section2.current.style.transition = Isclicked
          ? "width 0.5s out"
          : "width 0.5s ease";
        setTimeout(() => {
          navigate("/circuit");
        }, 1000);

        // Section2.current.style.display = Isclicked ? "none" : "block";
      } else if (WhoisClicked === "Second") {
        Section2.current.style.width = Isclicked ? "100%" : "50%";
        Section1.current.style.width = Isclicked ? "0%" : "50%";
        Section2.current.style.transition = Isclicked
          ? "width 0.5s ease"
          : "width 0.5s out";
        Section1.current.style.transition = Isclicked
          ? "width 0.5s out"
          : "width 0.5s ease";
        setTimeout(() => {
          navigate("/patient", { state: { Isclicked } });
        }, 1000);
      }
    } else {
      if (WhoisClicked === "First") {
        navigate("/circuit");
      } else if (WhoisClicked === "Second") {
        navigate("/patient", { state: { Isclicked } });
      }
    }
  }, [Isclicked]);
  const handleClicked = (e) => {
    setclicked(!Isclicked);
    let ID = e.currentTarget.id;
    setWhoisClicked(ID);
    // console.log(Isclicked, ID);
    // console.log(ID);

    // console.log(section2.current.width);
  };

  return (
    <div>
      {isDesktopOrLaptop && (
        <>
        <div id="background" className="container-background">
          <div className="container-background-relative">
            <div className="background-cold"></div>
            <div className="background-warm"></div>
          </div>
        </div>
          <div id="First" ref={Section1} className="container-info">
            <div
              id="First"
              className="First_block1 btn blue"
              onClick={(e) => handleClicked(e)}
            >
              <div
                className="btn-container"
                style={{
                  position: "relative",
                }}
              >
                <CircuitButton />
              </div>
            </div>
          </div>
          <div ref={Section2} id="Second" className="container-main">
            <div
              id="Second"
              className="Second_block2 btn orange"
              onClick={(e) => handleClicked(e)}
            >
              <div
                className="btn-container"
                style={{
                  position: "relative",
                  // background: "red",
                }}
              >
                <PatientButton />
              </div>
            </div>
            {/* <h1>second screen</h1> */}
          </div>
        </>
      )}
      {/* {isBigScreen && <p>You have a huge screen</p>} */}
      {/* Mobile version !!  */}
      {isTabletOrMobile && (
        <>
          <div
            style={{
              width: "100%",
              height: "100vh",
              // background: "purple",
              display: "flex ",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {/* <p>You are a tablet or mobile Poretrait </p> */}
            <div className="container-info-mobile">
              <div
                id="First"
                ref={Section1}
                onClick={(e) => handleClicked(e)}
                className="Circuit_mobile echo"
              >
                <div className="echo1 ">
                  <CircuitButton />
                </div>
              </div>
            </div>
            <div
              ref={Section2}
              id="Second"
              className="container-main-mobile"
              onClick={(e) => handleClicked(e)}
            >
              <div className="Patient_mobile echo">
                <div className="echo1">
                  <PatientButton />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* {isTabletOrMobile && !isPortrait && (
        <>
        <p>You are a tablet or mobile landscape </p>
        </>
      )} */}
      {/* <p>Your are in {isPortrait ? "portrait" : "landscape"} orientation</p> */}
      {/* {isRetina && <p>You are retina</p>} */}
    </div>
  );
}
