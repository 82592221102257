// article is the main container
// must put section inside the article to add the margin bottom between sections
// div in the section will add margin to the left
// div.half_to_right will put the div to right in half size 50% to the parent
// ul and li still need a droplet for it but it work fines for now
// p will be just for breaking the line or paragraph
// b will be for bold
export function Slide2_5(props) {
  return (
    <article>
      <section>
        <p>
          La première conférence internationale portant sur la gestion
          personnalisée du capital sanguin, ou Patient Blood Management (PBM),
          s’est tenue à Francfort le 24 et 25 avril 2018. Elle était organisée à
          l’initiative de l’Alliance Européenne du Sang (European Blood
          Alliance) et supervisée par le Professeur Erhard Seifried de la Goethe
          University of Frankfurt. Cet évènement réunissait un comité
          scientifique composé de 200 experts dont 17 dans le domaine de
          l’hémothérapie et de la médecine transfusionnelle, tous étant
          concernés par les problématiques de la gestion du capital sanguin.
          Cette conférence a permis la mise en œuvre de recommandations
          essentielles à l’amélioration de la prise en charge des patients
          nécessitant une transfusion sanguine<sup>1</sup>.
        </p>
      </section>

      <section>
        <p>
          <h3>
            L’anémie préopératoire au cœur de la conférence internationale de
            consensus sur la gestion du sang<sup>2</sup>
          </h3>
        </p>
        <p>
          Trois questions principales ont été soulevées concernant la gestion de
          l’anémie préopératoire dans le PBM :
        </p>
        <ul>
          <li>
            {" "}
            Chez les patients devant subir une intervention programmée, l’anémie
            préopératoire pourrait-elle nuire à la sécurité clinique et
            engendrer des surcoûts contrairement à une situation où les patients
            ne souffriraient pas d’anémie ?
          </li>
          <li>
            Pour les patients devant subir une intervention programmée, un seuil
            d’hémoglobinémie devrait-il être défini ?
          </li>
          <li>
            Chez les patients anémiés devant subir une intervention programmée,
            la transfusion de culots globulaires ou une supplémentation en fer
            et/ou en agents stimulant l&#39;érythropoïèse (ASE) est-elle
            efficace cliniquement et économiquement en comparaison avec
            l’absence de traitement, l’administration d’un placebo ou d’un
            traitement standard
          </li>
        </ul>
      </section>

      <section>
        <p>
          En réponse à ces questions, les recommandations suivantes concernant
          la gestion de l’anémie préopératoire dans le PBM ont été proposées :
        </p>
      </section>

      <section>
        <p>
          <b>
            Détecter et soigner l’anémie préopératoire à temps (recommandation
            forte)
          </b>
        </p>
        <p>
          L’anémie préopératoire peut être lourde de conséquences pour les
          patients puisqu’elle donne lieu à une augmentation de la
          morbi-mortalité post-opératoire (accidents vasculaires cérébraux
          ischémiques, infarctus du myocarde, insuffisance rénale…). Sa
          détection et sa gestion suffisamment en amont de l’intervention
          chirurgicale est fortement recommandée.
        </p>
      </section>

      <section>
        <p>
          <b>
            Une supplémentation en fer pour éviter les complications
            peropératoires (recommandation modérée)
          </b>
        </p>
        <p>
          L’expérience a montré qu’une supplémentation en fer permettait de
          réduire la transfusion sanguine dans de nombreux cas. Une
          supplémentation en fer chez les adultes ayant une anémie ferriprive
          est donc recommandée dans certaines conditions.
        </p>
      </section>

      <section>
        <p>
          <b>
            L’utilisation d’agents stimulant l&#39;érythropoïèse : une
            efficacité controversée (recommandation modérée)
          </b>
        </p>
        <p>
          L’efficacité d’agents stimulant l&#39;érythropoïèse (ASE) n’étant pas
          avérée, la prescription de ces derniers pour des patients atteints
          d’anémie préopératoire ne doit pas être systématique.
        </p>
      </section>

      <section>
        <p>
          <b>
            La supplémentation en fer et en érythropoïétine en association
            (recommandation modérée)
          </b>
        </p>
        <p>
          Afin de réduire les risques de transfusion, la supplémentation en fer
          et en érythropoïétine est recommandée sous certaines conditions pour
          les patients adultes présentant un taux d’hémoglobine inférieur à 13
          g/dL préalablement à une chirurgie orthopédique lourde et programmée.
        </p>
      </section>
      <section>
        <p>
          <h3>
            Les recommandations concernant les seuils de transfusion de
            concentrés de globules rouges ou de transfusion sanguine²
          </h3>
        </p>
        <p>
          Lors de la Conférence du Consensus de Francfort, quatre
          recommandations ont été émises concernant la transfusion de concentré
          de globules rouges :
        </p>
      </section>
      <section>
        <p>
          <b>Les soins intensifs (recommandation forte)</b>
        </p>
        <p>
          Le panel recommande fortement de restreindre la transfusion de culots
          globulaires aux patients dans un état critique mais stable, avec une
          hémoglobinémie strictement inférieure à 7 g/dL.
        </p>
      </section>
      <section>
        <p>
          <b>La chirurgie cardiaque (recommandation forte)</b>
        </p>
        <p>
          Le panel recommande fortement de restreindre la transfusion de culot
          globulaires aux patients avec une hémoglobinémie strictement
          inférieure à 7,5 g/dL
        </p>
      </section>
      <section>
        <p>
          <b>La chirurgie orthopédique de la hanche (recommandation modérée)</b>
        </p>
        <p>
          Chez les personnes devant subir une intervention chirurgicale pour une
          fracture de la hanche avec des risques de maladies cardiovasculaires,
          le taux d’hémoglobine recommandé, soumis à certaines conditions, doit
          être inférieur à 8g/dL afin d’envisager une transfusion de concentré
          de globules rouges.
        </p>
      </section>
      <section>
        <p>
          <b>Le saignement gastrointestinal aigüe (recommandation modérée)</b>
        </p>
        <p>
          La transfusion sanguine restrictive est recommandée sous certaines
          conditions chez des patients hémodynamiquement stables souffrant de
          saignement gastrointestinal aigüe et avec une hémoglobinémie
          inférieure à 7-8 g/dL.
        </p>
      </section>
      <section>
        <p>
          <h3>
            L’encadrement des programmes de Patient Blood Management (PBM)
            <sup>2</sup>{" "}
          </h3>
        </p>
        <p>
          <b>
            La nécessité de mettre en place des programmes de PBM
            (recommandation modérée)
          </b>
        </p>
        <p>
          La mise en place de programmes de gestion personnalisée du capital
          sanguin (PBM) est recommandée pour améliorer l’utilisation des
          ressources sanguines.
        </p>
      </section>
      <section>
        <p>
          <b>
            Des systèmes d’aide à la prise de décision (recommandation modérée)
          </b>
        </p>
        <p>
          Les experts présents à la Conférence du Consensus de Francfort
          recommandent l’utilisation d’outils électroniques pour optimiser le
          recours au concentré de globules rouges. Une comparaison de trois
          études menées à ce sujet a permis d’évaluer l’efficacité de ces
          systèmes dans la gestion du capital sanguin.
          <br /> En 2018, la Conférence du Consensus de Francfort a permis de
          mettre en exergue l’efficacité des programmes de PBM dans la gestion
          du capital sanguin de plusieurs types de patients.
        </p>
      </section>
      <section>
        <p>
          Dans le cadre de la Conférence du Consensus de Francfort, 20 études
          concernant l’efficacité des programmes de PBM ont été comparées. Les
          stratégies du PBM ont réduit l'incidence de transfusion de 22% (RR,
          0.78 [95% CI, 0.73-0.85]). La quantité de plasma frais congelé a elle
          aussi été réduite de 51% (RR, 0.49 [0.23-1.06]).
        </p>
      </section>
      <br></br>
      <section>
        <p>
          <sup>1</sup>International consensus conference on patient blood
          management, sur le site de European Blood Alliance [En ligne],{" "}
          <a
            href="https://europeanbloodalliance.eu/activities/icc-pbm/"
            target="_blank"
          >
            https://europeanbloodalliance.eu/activities/icc-pbm/
          </a>{" "}
          (page consultée le 27 décembre 2021).
        </p>
        <p>
          <sup>2</sup>Mueller.M.M et al, Patient Blood Management :
          Recommendations From the 2018 Frankfurt Consensus Conference, JAMA,
          mars 2019, V321, p983-993, doi: 10.1001/jama.2019.0554{" "}
          <a
            href="https://www.mscbs.gob.es/profesionales/saludPublica/medicinaTransfusional/congresos/docs/ConferenciaConsensoPBM_Frankfurt2018.pdf"
            target="_blank"
          >
            https://www.mscbs.gob.es/profesionales/saludPublica/medicinaTransfusional/congresos/docs/ConferenciaConsensoPBM_Frankfurt2018.pdf
          </a>{" "}
        </p>
      </section>
    </article>
  );
}
