import React, { useEffect, useState } from "react";
import Scroll from "./buttons/Scroll";
import { Slider } from "./slider";
import { Slide2_1 } from "./slides/2/Slide2_1.jsx";
import { Slide2_2 } from "./slides/2/Slide2_2.jsx";
import { Slide2_3 } from "./slides/2/Slide2_3.jsx";
import { Slide2_4 } from "./slides/2/Slide2_4.jsx";
import { Slide2_5 } from "./slides/2/Slide2_5.jsx";
import { ReactComponent as PatientBar } from "../assets/images/Group_137.svg";
import { ReactComponent as PatientBarMobile } from "../assets/images/Group_137.svg";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { useSearchParams } from "react-router-dom";

const slides = [
  {
    slide: Slide2_1,
    title: "Introduction au PBM",
    subTitle: "Histoire du PBM",
    time: "11 min",
    nextButton: "Effets secondaires...",
    size: "minor",
  },
  {
    slide: Slide2_2,
    title:
      "Transfusion sanguine dans le Patient Blood Management : les effets secondaires possibles",
    subTitle: "",
    time: "5 min",
    nextButton: "Bénéfices médico...",
    size: "minor",
  },
  // {
  //   slide: Slide2_3,
  //   title:
  //     "Infographie descriptive du PBM sur Ia mise en lace et Ia rise en char e.",
  //   subTitle: "hellooo",
  //   time: "4 min",
  //   nextButton: "",
  //   size: "major",
  // },
  {
    slide: Slide2_4,
    title: "Bénéfices médico économiques du PBM",
    subTitle: "",
    time: "8 min",
    nextButton: "Conférence du consensus...",
    size: "major",
  },
  {
    slide: Slide2_5,
    title: "Conférence du consensus de Francfort",
    subTitle: "",
    time: "12 min",
    nextButton: "",
    size: "major",
  },
];

export default function Patient(props) {
  const [isClicked, setisClicked] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1251 });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1250 });
  let [searchParams, setSearchParams] = useSearchParams();

  // TODO: Delete ?
  // const isBigScreen = useMediaQuery({ minWidth: 1824 });
  // const isPortrait = useMediaQuery({ orientation: "portrait" });
  // const isRetina = useMediaQuery({ minResolution: "2dppx" });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    let IDLOGO = document.getElementById("logoPrincipale");

    if (isClicked) {
      IDLOGO.style.fill = "unset";
    } else {
      setSearchParams({}, { replace: true });
    }
    return () => {
      IDLOGO.style.fill = "";
    };
  }, [isClicked]);
  useEffect(() => {
    // ^ => XOR GATE
    if (isDesktopOrLaptop ^ isTabletOrMobile) {
      const fn = (event) => {
        const target = event.target.closest("text");
        const page = target.dataset["page"];
        goTo(page);
        // window.history.back()
      };
      const goTo = (page) => {
        setSearchParams({ page }, { replace: true });
        setisClicked(true);
      };
      const page = searchParams.get("page");
      if (Number.isInteger(parseInt(page))) {
        goTo(page);
      }
      const links = document.querySelectorAll("text[data-page]");
      links.forEach((el) => el.addEventListener("click", fn));
      return () => {
        links.forEach((el) => el.removeEventListener("click", fn));
      };
    }
  }, [isDesktopOrLaptop, isTabletOrMobile]);
  return (
    <>
      {isDesktopOrLaptop && (
        <div className="patient">
          <div
            className={classNames(
              "Patient_block",
              isClicked ? "reversedAnimation" : "forrwardAnimation"
            )}
            style={{ width: "33%" }}
          >
            <div className="headerBlock"></div>
            <div style={{ marginLeft: "2rem" }}>
              {" "}
              <PatientBar className="Image" />
            </div>
          </div>
          <div
            className="circuit__scroll"
            onClick={() => setisClicked(!isClicked)}
          >
            <div
              style={{ height: "100%", width: "100%", position: "relative" }}
            >
              <Scroll
                style={{
                  rotate: isClicked ? "180deg" : "0deg",
                  display: "block",
                }}
              />
            </div>
          </div>
          <div
            className="Circuit_sliderBlock"
            // className={classNames(
            //   "Circuit_sliderBlock",
            //   isClicked ? "ToptoBottom" : "ToptoBottomTOP"
            // )}
            style={isClicked ? { top: "0%" } : { top: "100%" }}
          >
            <Slider key={isClicked} slides={slides} displayed={isClicked} />
          </div>
        </div>
      )}
      {isTabletOrMobile && (
        <>
          <>
            <div
              className="Circuit_mob"
              style={{
                height: "100vh",
                width: "100%",
                display: isClicked && "none",
              }}
            >
              <div className="patient " style={{ minHeight: "50vh" }}></div>
              <div
                className={classNames("Patient_block reversedAnimation-mobile")}
                // style={isClicked ? { top: "100%" } : { top: "0%" }}
                style={{
                  width: "100%",
                  height: "50vh",
                  bottom: "0",
                }}
              >
                <div
                  style={{
                    marginLeft: "1.5rem",
                    marginTop: "1rem",
                    width: `60%`,
                  }}
                >
                  {" "}
                  <PatientBarMobile className="Image" />
                </div>
                <div
                  className="circuit__scroll"
                  onClick={() => setisClicked(!isClicked)}
                  style={{
                    zIndex: 2,
                    bottom: isClicked ? "84%" : "3%",
                    left: isClicked ? "75%" : "75%",
                    transition: " all 1s ease",
                  }}
                ></div>
              </div>
            </div>
          </>
          <div
            style={{
              height: "15%",
              width: isMobile ? "13%" : "7%",
              position: "fixed",
              bottom: isClicked ? "80%" : "1%",
              right: "6%",
              zIndex: 2,
              transition: "all ease 0.75s",
            }}
          >
            <Scroll
              isTabletOrMobile={isTabletOrMobile}
              style={{
                rotate: isClicked ? "180deg" : "0deg",
                display: "block",
                transition: "rotate ease 0.5s",
              }}
              onClick={() => setisClicked(!isClicked)}
            />
          </div>
          <div
            className="Circuit_sliderBlock"
            style={
              isClicked
                ? { top: "0%", width: "100%" }
                : { top: "100%", width: "100%", display: "none" }
            }
          >
            <Slider displayed={isClicked} key={isClicked} slides={slides} />
            {/* </> */}
            {/* )} */}
          </div>
        </>
      )}
    </>
  );
}
